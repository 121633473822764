<template>
	<div v-if="charge" class="charge-panel p-2 mb-4">
		<div v-if="charge.chargingStatus == 'RESERVED'">
			<div class="text-center alert-info py-2 bigger-alert">
				<font-awesome-icon icon="spinner" size="lg" class="fa-spin mr-2" />
				{{ $t('charge-details.waiting') }}
			</div>
		</div>

		<div class="text-center" v-else-if="charge.chargingStatus == 'NEW'">
			<div class="alert-info py-2 bigger-alert"><font-awesome-icon icon="spinner" size="lg" class="fa-spin mr-2" /> {{ $t('charge-details.waiting-for-payment') }}</div>

			<div class="my-2">
				<div v-if="charge.paymentUrl">
					{{ $t('charge-details.back-to-pay-prefix') }}
					<a :href="charge.paymentUrl">{{ $t('charge-details.back-to-pay-link') }} <font-awesome-icon icon="chevron-right" /></a>
				</div>
				<div>{{ $t('charge-details.back-to-pay-cancel') }}</div>
			</div>
		</div>

		<div v-else-if="charge.chargingStatus == 'STARTING'">
			<div class="text-center alert-info py-2 bigger-alert mb-3">
				<font-awesome-icon icon="spinner" size="lg" class="fa-spin mr-2" />
				{{ $t('charge-details.please-connect-car') }}
			</div>
		</div>

		<div class="text-center" v-if="cancelBtnVisible && charge.chargingStatus == 'NEW'">
			<button @click="cancelChargingBeforeStart" class="btn btn-danger location-panel-charger-button">
				<font-awesome-icon icon="times" class="fa-2x mr-3" style="vertical-align: middle; margin-bottom: 2px" />
				{{ $t('charge-details.cancel') }}
			</button>
		</div>

		<div v-else>
			<div class="charge-card-container">
				<div class="card-body">
					<div class="charge-card-title text-center">
						<h2 class="card-title my-1">{{ charge.consumedEnergy }} kWh</h2>
					</div>

					<div class="charge-progress-container">
						<div class="progress charge-progress" v-if="showProgress">
							<div class="progress-bar" role="progressbar" :class="!stopped && charge.chargingStatus === 'STARTED' ? 'progress-bar-striped progress-bar-animated' : 'bg-success'" :style="progressStyle" :aria-valuenow="progressStyle" :aria-valuemin="0" :aria-valuemax="100"></div>
						</div>
						<div class="row" :class="!stopped && charge.chargingStatus === 'STARTED' ? 'charge-progress-text' : 'charge-progress-text-finished'">
							<template v-if="showProgress">
								<div class="col-3 text-left">0 kWh</div>
								<div class="col-6 text-center bigger-font">{{ $t(charge.chargingStatus) }}</div>
								<div class="col-3 text-right">{{ round2(charge.bookedEnergy) }} kWh</div>
							</template>
							<div v-else class="col-12 text-center bigger-font">{{ $t(charge.chargingStatus) }}</div>
						</div>
					</div>

					<div class="card-text">
						<div class="charge-card-text text-left">
							<div class="row my-3" v-if="elapsedTimePrompt">
								<div class="col-7 col-md-8">
									<b>{{ $t('charge-details.elapsed-time') }}</b>
								</div>
								<div class="col-5 col-md-4">{{ elapsedTimePrompt }}</div>
							</div>
							<div class="row my-3" v-if="charge.bookedTime">
								<div class="col-7 col-md-8">
									<b>{{ $t('charge-details.booked-time') }}</b>
								</div>
								<div class="col-5 col-md-4">{{ charge.bookedTime }} perc</div>
							</div>
							<div class="row my-3" v-if="charge.consumptionPower">
								<div class="col-7 col-md-8">
									<b>{{ $t('charge-details.consumptionPower') }}</b>
								</div>
								<div class="col-5 col-md-4">{{ round2(charge.consumptionPower) }} kW</div>
							</div>
							<div class="row my-3">
								<div class="col-7 col-md-8">
									<b>{{ $t('charge-details.charger-name') }}</b>
								</div>
								<div class="col-5 col-md-4">{{ charge.charger }}</div>
							</div>
							<div class="row my-3" v-if="showProgress">
								<div class="col-7 col-md-8">
									<b>{{ $t('charge-details.booked-energy') }}</b>
								</div>
								<div class="col-5 col-md-4">{{ round2(charge.bookedEnergy) }} kWh</div>
							</div>

							<div class="row stop-btn-row">
								<div class="form-group col-12 text-center" v-if="(charge.chargingStatus == 'STARTED' || charge.chargingStatus == 'STARTING') && stopBtnVisible">
									<button @click="stopCharging" class="btn btn-danger location-panel-charger-button">
										<font-awesome-icon icon="pause" class="fa-2x mr-3" style="vertical-align: middle; margin-bottom: 2px" />
										{{ $t('chargerView.stopCharging') }}
									</button>
								</div>
								<div v-else-if="charge.chargingStatus == 'STARTED' && !stopBtnVisible" class="col-12">
									<div class="text-center alert-info py-2 bigger-alert">
										<font-awesome-icon icon="hourglass-start" class="mr-2" />
										{{ $t('charge-details.stop-request-sent') }}
									</div>
									<div class="text-center spin-icon">
										<font-awesome-icon icon="spinner" size="2x" class="fa-spin mr-2" />
									</div>
								</div>
								<div v-else-if="charge.chargingStatus == 'FINISHING'" class="col-12">
									<template v-if="!charge.countParkingFee">
										<div class="text-center alert-info px-4 py-2 rounded bigger-alert">
											<font-awesome-icon icon="receipt" class="mr-2" />
											{{ $t('charge-details.stop-charging') }}
										</div>
										<div class="text-center spin-icon">
											<font-awesome-icon icon="spinner" size="2x" class="fa-spin mr-2" />
										</div>
									</template>
									<template v-else>
										<div class="text-center alert-info px-4 py-2 rounded bigger-alert">
											<font-awesome-icon icon="receipt" class="mr-2" />
											{{ $t('charge-details.charging-finished', [charge.minutesAfterParkingStart]) }}
										</div>
									</template>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ChargeDetailsPanel',
	props: {
		shouldRoute: Boolean
	},
	data() {
		return {
			progressStyle: 'width: 0%',
			showProgress: true,
			charge: null,
			timer: null,
			stopBtnVisible: true,
			cancelBtnVisible: true,
			stopped: false,
			elapsedTimePrompt: null,
			elapsedTimeTimer: null
		};
	},
	methods: {
		refresh(navigate = true) {
			this.$rest.getCurrentCharge(false, (responseData) => {
				this.$emit('chargedata', responseData);
				if (this.charge && !responseData && this.shouldRoute) {
					this.$router.push('/charge-history');
				}
				this.charge = responseData;
				if (this.charge) {
					let percentage = 0;
					this.showProgress = !!this.charge.bookedEnergy;
					if (this.showProgress) {
						percentage = (this.charge.consumedEnergy / this.charge.bookedEnergy) * 100;
					}

					if (percentage >= 100) {
						this.stopped = true;
					}
					this.progressStyle = `width: ${percentage}%;`;
					this.startElapsedTime();
				} else {
					if (!navigate) return;
					setTimeout(() => {
						this.$router.push('/coupons');
					}, 5000);
				}
			});
		},
		startElapsedTime() {
			if (this.elapsedTimeTimer) {
				clearInterval(this.elapsedTimeTimer);
			}
			this.elapsedTimeTimer = setInterval(() => {
				this.calculateElapsedTime();
				this.$forceUpdate();
			}, 1000);
		},
		calculateElapsedTime() {
			if (this.charge && this.charge.startTime) {
				const now = new Date();
				const start = Date.parse(this.charge.startTime);
				const diffSec = (now - start) / 1000;
				const minutes = Math.floor(diffSec / 60);
				const seconds = Math.floor(diffSec - minutes * 60);
				this.elapsedTimePrompt = minutes + ' ' + this.$t('chargerView.minutes') + ' ' + seconds + ' ' + this.$t('chargerView.seconds');
			} else {
				this.elapsedTimePrompt = null;
			}
		},
		stopCharging() {
			if (confirm(this.$t('charge-details.stop-charge-confirm'))) {
				this.stopBtnVisible = false;
				const chargeId = this.charge.chargerId;
				this.$rest.stopCharging(chargeId, this.charge.id, (responseData) => {
					this.refresh();
				});
				setTimeout(() => {
					this.stopBtnVisible = true;
				}, 15000);
			}
		},
		cancelChargingBeforeStart() {
			if (confirm(this.$t('charge-details.cancel-confirm'))) {
				this.cancelBtnVisible = false;
				this.$rest.cancelChargingBeforeStart(this.charge.id, (responseData) => {
					this.refresh(false);
				});
				setTimeout(() => {
					this.cancelBtnVisible = true;
				}, 15000);
			}
		},
		round2(num) {
			return +(Math.round(num + 'e+2') + 'e-2');
		}
	},
	mounted() {
		this.stopBtnVisible = true;
		this.cancelBtnVisible = true;
		this.refresh();
		this.timer = setInterval(() => {
			this.refresh();
		}, 10000);
	},
	beforeDestroy() {
		if (this.timer) {
			clearInterval(this.timer);
			clearInterval(this.elapsedTimeTimer);
			this.timer = null;
		}
	},
	emits: ['chargedata']
};
</script>

<style>
.charge-panel {
	border-radius: 15px;
}

@-webkit-keyframes progress-bar-stripes-back {
	from {
		background-position: 0 0;
	}
	to {
		background-position: 1rem 0;
	}
}

@keyframes progress-bar-stripes-back {
	from {
		background-position: 0 0;
	}
	to {
		background-position: 1rem 0;
	}
}

.progress-bar-animated {
	-webkit-animation: progress-bar-stripes-back 1s linear infinite !important;
	animation: progress-bar-stripes-back 1s linear infinite !important;
}

.charge-progress-text {
	margin-top: 0.5rem;
	font-weight: bold;
	color: darkblue;
}

.charge-progress-text-finished {
	margin-top: 0.5rem;
	font-weight: bold;
	color: green;
}

.bigger-alert {
	font-size: 1.3rem;
	border-radius: 6px;
}

.charge-progress-container {
	margin-bottom: 3rem;
}

.bigger-font {
	font-size: 1.2rem;
}

.charge-progress {
	height: 20px !important;
	border-radius: 1rem !important;
}

.charge-card-title {
	margin-bottom: 1.3rem;
	margin-top: 1rem;
}

.stop-btn-row {
	margin-top: 3rem;
}

.charge-card-text {
	max-width: 30rem;
	margin: auto;
}

.spin-icon {
	margin-top: 1rem;
}

.charge-card-container {
	max-width: 40rem;
	margin: auto;
	box-shadow: 0 0 5px grey;
	background-color: lightcyan;
	border-radius: 10px;
}
</style>
