<template>
	<div class="d-flex flex-wrap justify-content-center">
		<div class="wallet-outer2" v-for="card in creditCards" :key="card.cardId">
			<CreditCard :card="card" @changed="getCreditCards()" />
		</div>
		<slot></slot>
	</div>
</template>

<script>
import CreditCard from '@/components/CreditCard'

export default {
	name: 'CreditCards',
	components: {
		CreditCard
	},
	data() {
		return {
			creditCards: [],
			timer: null
		}
	},
	methods: {
		refresh() {
			this.getCreditCards()
			this.killTimer()
			this.timer = setTimeout(() => {
				this.refresh()
			}, 5000)
		},

		killTimer() {
			if(this.timer) {
				clearTimeout(this.timer)
				this.timer = null
			}
		},

		getCreditCards() {
			this.$rest.getCreditCards(data => {
				this.creditCards = data
				this.$emit('changed', this.creditCards)
			})
		},
	},
	beforeMount() {
		this.refresh()
	},
	beforeDestroy() {
		this.killTimer()
	},
	emits: ['changed']
}
</script>