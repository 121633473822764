<template>

	<div v-if="result">
		<div v-if="result==='success'">

			<div class="reg-res-card">
				<div class="card ">
					<div class="card-body">
						<h1 class="card-title my-3 text-center">{{ $t('registration-result.successful-reg') }}</h1>
						<div class="card-text text-center">
							<router-link to="login" tag="button" class="btn btn-primary reg-res-login">
								<font-awesome-icon icon="sign-in-alt"/>
								{{ $t('registration-result.go-to-login') }}
							</router-link>
						</div>
						<div v-if="isMobile()" class="text-center card-text">
							<a href="freecharger://main" class="btn btn-warning btn-lg my-2">{{ $t('registration-result.start-app') }}</a>
						</div>
					</div>
				</div>
			</div>

		</div>
		<div v-else>
			<div class="reg-res-card">
				<div class="card ">
					<div class="card-body">
						<h1 class="card-title my-3 text-center">{{ $t('registration-result.unsuccessful-reg') }}</h1>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>

export default {
	name: 'RegistrationResultView',
	data() {
		return {
			result: null
		}
	},
	methods: {
		isMobile(){
			//console.log(navigator.userAgent)
			if(/Android/i.test(navigator.userAgent)){
				return true
			}
			return false
		},
		parseResult() {
			this.result = this.$route.query.v
		}
	},
	beforeMount() {
		this.parseResult()
	}

}

</script>

<style scoped>
.reg-res-card {
  box-shadow: 0 0 3px lightgrey;
}

.reg-res-login{
  margin-top: 2rem;
}

</style>
