<template>
	<div class="parking-card-container">
		<div class="card ">
			<div class="card-body">
				<h1 class="card-title my-3">{{ time }} </h1>
				<div class="card-text">
					<div class="parking-card-text-area text-left">
						<div class="row my-2 align-items-center">
							<div class="col-7">
								<b>{{ $t('parking-card.dateStart') }}:</b>
							</div>
							<div class="col-5" v-if="data.parkingFrom">{{ formatDate(data.parkingFrom) }}</div>
						</div>

						<div class="row my-2 align-items-center">
							<div class="col-7">
								<b>{{ $t('parking-card.parking-cost') }}:</b>
							</div>
							<div class="col-5">{{ data.amount }} forint</div>
						</div>

            <div class="my-4 text-center">
              {{ data.parkingLot.location.address.full }}
            </div>

						<div class="mt-4 mb-2 text-center d-flex justify-content-center" style="gap: 1rem" v-if="data.parkingLot.id">
							<button @click="startNewPark(data)" class="btn btn-success">
								<font-awesome-icon icon="parking" />
								{{ $t('parking-card.startNewPark') }}
							</button>
              <button @click="startNewSurcharge(data)" class="btn btn-warning">
                <font-awesome-icon icon="parking" />
                {{ $t('parking-card.startSurcharge') }}
              </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ParkingCard',
	props: ['data', 'casualCharge'],
  data() {
    return {
      app: null,
    };
  },
  created() {
    this.app = this.$root.$children[0]
  },
  asyncComputed: {
		async loggedInUserData() {
			return await this.$store.getters.getLoggedInUserData;
		}
	},
  computed: {
    time() {
      const start = new Date(this.data.parkingFrom);
      const end = new Date(this.data.parkingTo);
      const diff = end - start;
      const minutes = Math.floor(diff / 1000 / 60);

      let hours = Math.floor(minutes / 60);
      let minutesLeft = minutes - hours * 60;

      if(isNaN(hours)) hours = 0;
      if(isNaN(minutesLeft)) minutesLeft = 0;

      if (hours === 0) {
        return `${minutesLeft} perc`;
      }


      return `${hours} óra ${minutesLeft} perc`;
    }
  },
	methods: {
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const formattedDate = `${date.getFullYear()}.${this.pad(date.getMonth() + 1)}.${this.pad(date.getDate())} ${this.pad(date.getHours())}:${this.pad(date.getMinutes())}`;
      return formattedDate;
    },
		round2(num) {
			return +(Math.round(num + 'e+2') + 'e-2');
		},
    pad(num) {
      return String(num).padStart(2, '0');
    },
		startNewPark(data) {
			this.app.logoutCasualUserIfNeeded(true);
			this.$router.push({name: 'index', params: {command: 'showLocation', locationId: data.parkingLot.location.id}});
		},
    startNewSurcharge(data) {
      this.$router.push({name: 'parking-surcharge', query: {email: this.data.email, parkCode: data.chargeCode}});
    }
	}
};
</script>

<style scoped>
.parking-card-container {
	max-width: 40rem;
	margin: auto;
	box-shadow: 0 0 3px grey;
}

.parking-card-text-area {
	font-size: 1.2rem;
	max-width: 35rem;
	margin: auto;
}
</style>
