<template>
	<div>
		<div class="form-group">
			<div class="media align-items-center">
				<span class="step-number">{{ step }}</span>
				<div class="media-body" v-if="priceSettings.freeCharging">
					{{ $t('charger-start.headerFree') }}
				</div>
				<div class="media-body" v-else>
					{{ $t('charger-start.headerPay') }}
				</div>
			</div>
		</div>

		<div class="d-flex flex-wrap" v-if="!priceSettings.freeCharging">

			<div class="my-1 mx-auto">
				<button class="credit-card credit-card-simple" :class="{ highlightselected: selectedMode == 'simple' }" @click="selectSimplePay()">
					<img alt="simplepay logo" src="../assets/simplepay/simplepay.png" class="img-fluid" />
				</button>
			</div>

			<div class="my-1 mx-auto" v-for="card in creditCards" :key="card.cardId">
				<button class="credit-card credit-card-card" :class="{ highlightselected: selectedMode == 'card' && card.cardId === selectedCard }" @click="selectCard(card.cardId)">
					<span class="card-number">{{ card.cardMask }}</span>
					<span class="card-comment">{{ $t('charger-start.payWithCard') }}</span>
					<span class="card-expiry">{{ formatDate(card.expiry) }}</span>
				</button>
			</div>

			<div class="my-1 mx-auto" v-if="showWallet">
				<button class="credit-card credit-card-wallet" :class="{ highlightselected: selectedMode == 'wallet' }" @click="selectWallet()">
					<span class="card-number">{{ $t('menu.wallet') }}</span>
					<span class="card-comment">{{ $t('chargerView.prepaid') }}</span>
					<span class="card-ballance">{{ walletBalance }}<span style="font-size: 0.8em"> Ft</span></span>
				</button>
			</div>

		</div>

		<div v-if="selectedMode || priceSettings.freeCharging">
			<div class="text-center my-3" v-if="selectedMode == 'simple'">
				<a href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank">
					<img alt="simplepay logo" src="../assets/simplepay/simplepay_bankcard_logos_left_482x40.png" class="img-fluid"/>
				</a>
			</div>

			<div class="alert alert-warning mt-2 mx-1" v-if="selectedMode == 'simple'">
				<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2"/>
				{{ $t('charger-view.before-charger-1') }}
			</div>
			<div class="alert alert-warning mt-2 mx-1" v-else>
				<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2"/>
				{{ $t('charger-view.before-charger-2') }}
			</div>

			<div class="alert alert-info mt-2 mx-1 py-1">
				<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2"/>
				<i18n path="charger-view.privacy-disclaimer">
					<template v-slot:link1>
						<a href="#/privacy-statement" target="_blank">{{ $t('charger-view.privacy-policy') }}</a>
					</template>
					<template v-slot:link2>
						<a href="#/gtc" target="_blank">{{ $t('charger-view.general-conditions') }}</a>
					</template>
				</i18n>
			</div>

			<ParkingCodeInput @code="$e => parkCode = $e" v-if="charger.locationHasParking" />

			<div class="text-center">
				<button v-debounce:250="generalStartCharging" debounce-events="click" class="btn btn-success my-2">
					<font-awesome-icon icon="play" class="fa-2x mr-3" style="vertical-align: middle; margin-bottom: 2px;"/>
					{{ $t('chargerView.startCharging') }}
				</button>
				<a name="btnCharge"></a>
			</div>

		</div>

	</div>
</template>

<script>
import * as utils from '@/plugins/utils.js'
import StartChargeTutorial from '@/components/StartChargeTutorial.vue'
import ParkingCodeInput from "@/components/ParkingCodeInput.vue"

export default {
	name: "ChargeStartArea",
	components: {ParkingCodeInput},
	props: {
		step: Number,
		creditCards: Array,
		priceSettings: Object,
		charger: Object,
		selectedBillingData: Object,
		pickedAmount: Object,
		selectedCar: Object,
		walletBalance: Number,
		priceToPayNative: Number,
		msgs: Object
	},
	data() {
		return {
			app: null,
			selectedMode: null,
			selectedCard: null,
			noWizardNextTime: false,
			// creditCardSecret: ''
			parkCode: ''
		}
	},
	computed: {
		showWallet() {
			return this.walletBalance >= this.priceToPayNative
		}
	},
	created() {
		this.app = this.$root.$children[0]
	},
	mounted() {
		if(!this.showWallet) {
			this.selectSimplePay()
			if(this.creditCards) {
				for(let card of this.creditCards) {
					if(card.defaultCard) {
						this.selectCard(card.cardId)
						break
					}
				}
			}
		}
	},
	methods: {
		selectSimplePay() {
			this.selectedMode = 'simple'
			this.selectedCard = null
			utils.scrollToAnchor('btnCharge')
		},

		selectWallet() {
			this.selectedMode = 'wallet'
			this.selectedCard = null
			utils.scrollToAnchor('btnCharge')
		},

		selectCard(cardId) {
			this.selectedMode = 'card'
			this.selectedCard = cardId
			utils.scrollToAnchor('btnCharge')
		},

		formatDate(dateStr) {
			const date = new Date(dateStr)
			const formattedDate = `${date.getFullYear()}/${date.getMonth() + 1}`
			return formattedDate
		},

		generalStartCharging() {
			let components = [
				{ component: StartChargeTutorial }
			]

			//localStorage.removeItem('noChargingWizard');

			if(localStorage.getItem('noChargingWizard') == '1') {
				this.reallyStartCharging()
				return
			}

			this.app.showBSModal(components, this.$t('startChargeTutorial.header'), [], true,
				(event) => {
					if(event == 'START_CHARGE') {
						this.app.hideBSModal()
						if(this.noWizardNextTime) {
							localStorage.setItem('noChargingWizard', '1')
						}
						this.reallyStartCharging()
					}
				}, this)
		},

		reallyStartCharging() {
			if(this.priceSettings.freeCharging || this.selectedMode == 'simple') {
				this.chargeWithSimple()
			}
			else if(this.selectedMode == 'wallet') {
				this.chargeWithWallet()
			}
			else if(this.selectedMode == 'card') {
				this.chargeWithCard()
			}
		},

		chargeWithSimple() {
			this.$rest.startCharging(
				this.charger.id,
				this.selectedBillingData.id,
				this.selectedCar ? this.selectedCar.id : null,
				this.pickedAmount.time,
				this.pickedAmount.amount,
				this.parkCode,
				(paymentUrl) => {
					if(paymentUrl) {
						window.location.href = paymentUrl
					}
					else {
						this.$router.push("/charge-history")
					}
				},
				(error) => {
					this.showErrorMessage(error)
				}
			)
		},

		chargeWithWallet() {
			this.$rest.startChargingFromWallet(
				this.charger.id,
				this.selectedBillingData.id,
				this.selectedCar ? this.selectedCar.id : null,
				this.pickedAmount.time,
				this.pickedAmount.amount,
				this.parkCode,
				(responseData) => {
					if(responseData.result) {
						this.$store.dispatch('setWalletBalance', responseData.data)
						this.$router.push("/charge-history")
					}
					else {
						alert(this.$t(responseData.messages[0].messageKey))
					}
				},
				(error) => {
					this.showErrorMessage(error)
				}
			)
		},

		chargeWithCard() {
			this.$rest.startRegCardCharging(
				this.charger.id,
				this.selectedBillingData.id,
				this.selectedCar ? this.selectedCar.id : null,
				this.pickedAmount.time,
				this.pickedAmount.amount,
				this.selectedCard,
				navigator.language,
				navigator.javaEnabled(),
				screen.colorDepth,
				screen.height,
				screen.width,
				new Date().getTimezoneOffset(),
				window.platform,
				this.parkCode,
				(paymentUrl) => {
					if(paymentUrl) {
						window.location.href = paymentUrl
					}
					else {
						this.$router.push("/charge-history")
					}
				},
				(error) => {
					this.showErrorMessage(error)
				}
			)
		},

		showErrorMessage(error) {
			if(error.response.data.responseCode === 'CHARGER_NOT_ACTIVE') {
				alert(this.$t('charger-view.charger-not-available'))
			}
			else if(error.response.data.responseCode === "SIMPLEPAY_INVALID_PASSWORD") {
				alert(this.$t('charger-view.wrong-secret-code'))
			}
			else if(error.response.data.responseCode === "NO_ACTIVE_TARIFF") {
				alert(this.$t('charger-view.no-tariff'))
			}
			else if(error.response.data.responseCode === "NEGATIVE_WALLET") {
				alert(this.$t('charger-view.error-negative-wallet'))
				this.$router.push("/cards")
			}
			else {
				alert(this.$t('charger-view.unsuccessful-start'))
			}
		},

	}
}
</script>

<style scoped>
.highlightselected {
	border: 3px solid #509cd5;
}
</style>
