<template>
	<div>
		<div v-if="charger">
			<div class="alert alert-info align-items-center" v-if="loggedInUserData && loggedInUserData.casualUser">
				<font-awesome-icon icon="circle-notch" class="fa-spin fa-lg fa-fw mr-2" />
				{{ $t('charger-view.casual-charging-info') }}
			</div>
			<template v-else>
				<div class="media align-items-center">
					<div class="location-panel-charger-name d-flex flex-column justify-content-center align-items-center mr-2" v-if="charger.shortName">
						{{ charger.shortName }}
					</div>

					<div class="media-body">
						<div class="font-weight-bold">{{ charger.name }}</div>

						<div v-if="connectorStateKey === 'NEW_NOT_CONNECTED'" class="connector-state-unavailable" style="font-size: 0.9rem;">
							{{ $t('location-panel.charger-state-new') }}
						</div>
						<div v-else-if="connectorStateKey === 'UNKNOWN'" :class="['connector-state', connectorStateClass]" style="font-size: 0.9rem;">
							{{ $t('location-panel.communicationError') }}
						</div>
						<div v-else-if="connectorState" :class="['connector-state', connectorStateClass]" style="font-size: 0.9rem;">
							{{ connectorState }}
						</div>

						<div class="d-flex">
							<div style="font-size: 0.8rem;">{{ chargerPerformance }} kW {{ currentType }}</div>

							<div v-for="(outlet, index) in outlets" :key="index" class="outlet-item ml-3">
								<img v-if="outlet === 'Type 2'" class="charger-head-location" style="vertical-align: top;" src="../assets/connector/TYPE2_ico.png" />
								<img v-if="outlet === 'CHAdeMO'" class="charger-head-location" style="vertical-align: top;" src="../assets/connector/CHADEMO_ico.png" />
								<img v-if="outlet === 'CCS'" class="charger-head-location" style="vertical-align: top;" src="../assets/connector/CCS_ico.png" />
								<span style="font-size: 0.8rem; vertical-align: top;">{{ outlet }}</span>
							</div>
						</div>
					</div>
				</div>

				<small v-if="charger.location" class="text-center d-block">{{ charger.location.address.full }}</small>

				<div v-if="priceSettings" class="text-center">
					<small v-if="priceSettings.freeCharging" class="text-success">Ingyenes</small>
					<small v-else class="text-danger">{{ $t('location-panel.price', [priceSettings.unitChargingGross]) }}</small>
				</div>

				<button class="charger-info-toggle-button-2 mb-3 text-center d-block btn btn-outline-success" style="margin-top: -8px;" @click="toggleInfo(charger.id)">
					<font-awesome-icon icon="chevron-down" class="mr-1 animated-flip" :class="{ 'do-flip': showInfo }" />
					<template v-if="showInfo">
						<small>{{ $t('location-panel.hide-details') }}</small>
					</template>
					<template v-else>
						<small>{{ $t('location-panel.more-details') }}</small>
					</template>
				</button>

				<button class="scroll-down-message" @click="onScrollDown()" v-if="priceSettings && canStart && loggedInUserData">
					<font-awesome-icon icon="angle-double-down" size="lg" class="mr-2 animated-down-icon" />
					<span>{{ $t('charger-view.scroll-down') }}</span>
				</button>

				<div id="chargerDetails" class="collapse border p-2 mb-3">
					<div>
						<div v-if="this.tariffLines.length > 0" class="mt-2">{{ $t('location-panel.charger-tariff') }}:</div>
						<ul class="mb-0">
							<li v-for="tl in this.tariffLines" :key="tl">{{ tl }}</li>
						</ul>
					</div>

					<div class="row">
						<div class="col-md-6 col-xl-4" v-for="tv in traitDetails" :key="tv.trait.id">
							<hr />
							{{ tv.trait.name }}:
							<TraitValue :tv="tv" />
						</div>
					</div>
				</div>

				<div class="alert alert-warning" v-if="showChargeInProgressError">
					<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2" />
					{{ $t('charger-view.start-not-possible') }}
				</div>

				<div class="alert alert-warning" v-if="!priceSettings">
					<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2" />
					{{ $t('location-panel.error-no-tariff') }}
				</div>

				<div
					class="alert alert-warning"
					v-if="priceSettings && canStart && connectorStateKey === 'FINISHING' && charger.deviceType && charger.deviceType.canStartNewChargeWhileInFinishingState"
				>
					<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2" />
					{{ $t('charger-view.chargeInFinishingMessage') }}
				</div>

				<CasualChargingSurvey v-if="(priceSettings && canStart && !loggedInUserData) || loggedInUserData.casualCharge" :msgs="msgs" :priceSettings="priceSettings" :charger="charger" />

				<template v-else-if="priceSettings && canStart && loggedInUserData">
					<div class="form-group media" v-if="billingDatas && billingDatas.length > 1">
						<span class="step-number">{{ step1 }}</span>
						<div class="media-body align-self-center">{{ $t('charger-view.choose-billing-info') }}</div>
					</div>

					<div class="form-group">
						<template v-if="billingDatas && billingDatas.length > 0">
							<select class="form-control" v-model="selectedBillingData" v-if="billingDatas.length > 1">
								<option v-for="billingData in billingDatas" :key="billingData.id" :value="billingData"> {{ billingData.payerName }}, {{ billingData.address.full }} </option>
							</select>
							<div v-else>
								<b class="d-block">{{ $t('charger-view.billing-data') }}:</b>
								{{ billingDatas[0].payerName }}, {{ billingDatas[0].address.full }}
							</div>
						</template>

						<div class="alert alert-warning" v-if="!billingDatas || billingDatas.length === 0">
							<div>
								<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2" />
								<span style="font-size: 1.1rem;">{{ $t('charger-view.no-billing-inf') }}</span>
							</div>
							<router-link to="/billing">
								{{ $t('charger-view.please-add-billing-info') }}
							</router-link>
						</div>
					</div>

					<template v-if="selectedBillingData">
						<div class="alert alert-warning mt-1" v-if="(!cars || cars.length == 0) && $store.getters.getSettings.wizardCarsOptional">
							<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2" />
							<span class="mb-1 font-weight-bold">Nem vettél fel autót a fiókod alá!</span>
							<div class="mt-1">
								Ez nem probléma, viszont a következő lépésben csak fix összegek közül tudsz választani, és a nyugtán és számlán sem fog szerepelni a rendszámod.<br />
								Ha szeretnéd tudni megadni, hogy mennyi ideig tartson a töltés, vagy szeretnéd, hogy szerepeljen a nyugtán és a számlán a rendszámod, akkor ahhoz előbb a bal felső sarokból elérhető menü "Autóim" menüpontja alatt vegyél fel autót!
							</div>
						</div>
						
						<template v-if="cars && cars.length > 0 || !$store.getters.getSettings.wizardCarsOptional">
							<div class="form-group media" v-if="cars && cars.length > 1">
								<span class="step-number">{{ step2 }}</span>
								<div class="media-body align-self-center">{{ $t('charger-view.choose-car') }}</div>
							</div>

							<div class="form-group">
								<template v-if="cars && cars.length > 0">
									<select class="form-control" v-model="selectedCar" v-if="cars.length > 1">
										<option v-for="car in cars" :key="car.id" :value="car"> {{ car.producer }} {{ car.model }} - {{ car.licensePlate }} </option>
									</select>
									<div v-else>
										<b class="d-block">{{ $t('charger-view.car') }}:</b>
										{{ cars[0].producer }} {{ cars[0].model }} - {{ cars[0].licensePlate }}
									</div>
								</template>
								<div class="alert alert-warning px-3 py-2" style="text-align: center" v-else>
									<div>
										<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2" />
										<span style="font-size: 1.1rem;">{{ $t('charger-view.no-car') }}</span>
									</div>
									{{ $t('charger-view.no-car-description') }}<br />
									<router-link to="/cars">
										{{ $t('charger-view.add-car') }}
									</router-link>
								</div>
							</div>
						</template>

						<template v-if="selectedCar || $store.getters.getSettings.wizardCarsOptional">
							<a name="step3"></a>

							<div class="form-group media" v-if="!priceSettings.freeCharging && selectedCar">
								<span class="step-number">{{ step3 }}</span>
								<div class="media-body align-self-center">{{ $t('charger-view.reserve-mode') }}</div>
							</div>

							<div class="form-group text-center row" v-if="!priceSettings.freeCharging">
								<template v-if="selectedCar">
									<div class="px-3 py-2" :class="{ 'col-md-6': !priceSettings.freeCharging }">
										<a href="javascript:void(0)" class="charger-limit-type" :class="{ active: showTimePicker }" @click="onShowTimePicker">
											<span class="media align-items-center">
												<font-awesome-icon icon="stopwatch" size="2x" class="mr-2" />
												<span class="media-body">{{ $t('charger-view.max-charge-time') }}</span>
											</span>
										</a>
									</div>

									<div class="col-md-6 px-3 py-2" v-if="!priceSettings.freeCharging">
										<a href="javascript:void(0)" class="charger-limit-type" :class="{ active: showAmountPicker }" @click="onShowAmountPicker">
											<span class="media align-items-center">
												<font-awesome-icon :icon="['far', 'credit-card']" size="2x" class="mr-2" />
												<span class="media-body">{{ $t('charger-view.max-charge-cost') }}</span>
											</span>
										</a>
									</div>

									<!--div class="col-md-6 px-3 py-2" v-if="!priceSettings.freeCharging">
										<a href="javascript:void(0)" class="charger-limit-type" :class="{'active': pickedChargeMaxKW}" @click="onShowChargeMaxKWPicker">
											<span class="media align-items-center">
												<font-awesome-icon icon="bolt" size="2x" class="mr-2"/>
												<span class="media-body">{{ $t('charger-view.enter-enegery') }}</span>
											</span>
										</a>
									</div>

									<div class="col-md-6 px-3 py-2" v-if="!priceSettings.freeCharging">
										<a href="javascript:void(0)" class="charger-limit-type" :class="{'active': pickedChargeFinishTime}" @click="onShowChargeFinishTimePicker">
											<span class="media align-items-center">
												<font-awesome-icon :icon="['far', 'clock']" size="2x" class="mr-2"/>
												<span class="media-body">{{ $t('charger-view.enter-end-time') }}</span>
											</span>
										</a>
									</div-->
								</template>
							</div>

							<div class="alert alert-warning px-3 py-2" style="text-align: center" v-if="showHintForCarAddition && loggedInUserData">
								<div>
									<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2" />
									<span style="font-size: 1.1rem;">{{ $t('charger-view.need-mode-info-about-car') }}</span>
								</div>
								{{ $t('charger-view.need-more-info-about-car-description') }}<br />
								<router-link to="/cars">
									{{ $t('charger-view.add-charge-speed') }}
								</router-link>
							</div>

							<template v-if="showTimePicker || priceSettings.freeCharging">
								<a name="step4"></a>

								<div class="form-group" v-if="loggedInUserData">
									<div class="media align-items-center mb-2">
										<span class="step-number">{{ step4 }}</span>
										<div class="media-body">
											{{ $t('charger-view.charge-time') }}
										</div>
									</div>

									<div class="blockquote-footer step-list">
										{{ $t('charger-view.charge-disclaimer') }}
									</div>
									<div class="blockquote-footer step-list" v-if="!priceSettings.freeCharging">
										{{ $t('charger-view.charge-disclaimer-2') }}
									</div>
									<div class="blockquote-footer step-list" v-if="!priceSettings.freeCharging">
										{{ $t('charger-view.charge-disclaimer-3') }}
									</div>
									<div class="blockquote-footer step-list">
										{{ $t('charger-view.charge-disclaimer-4') }}
									</div>
									<div class="blockquote-footer step-list" v-if="!priceSettings.freeCharging && priceSettings.convenienceFeeGross > 0">
										<b>{{ $t('charger-view.estemated', [priceSettings.convenienceFeeGross]) }}</b>
									</div>
									<div class="blockquote-footer step-list" v-if="!priceSettings.freeCharging">
										<b>{{ $t('charger-view.calculation-unit-price', [priceSettings.unitChargingGross]) }}</b>
									</div>
								</div>

								<div class="row mb-3 px-3" v-if="loggedInUserData">
									<div class="col-lg-3 p-0">
										<div class="time-bubble-row">
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 15 }" @click="onTimePicked(15)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">15</span>
													<span class="time-bubble-label">{{ $tc('chargerView.minutes', 15) }}</span>
													<span class="time-bubble-performance" v-if="selectedCar">{{ calculateKwFromTime(15, 1) }} kWh</span>
												</span>
											</a>
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 30 }" @click="onTimePicked(30)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">30</span>
													<span class="time-bubble-label">{{ $tc('chargerView.minutes', 30) }}</span>
													<span class="time-bubble-performance" v-if="selectedCar">{{ calculateKwFromTime(30, 1) }} kWh</span>
												</span>
											</a>
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 45 }" @click="onTimePicked(45)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">45</span>
													<span class="time-bubble-label">{{ $tc('chargerView.minutes', 45) }}</span>
													<span class="time-bubble-performance" v-if="selectedCar">{{ calculateKwFromTime(45, 1) }} kWh</span>
												</span>
											</a>
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 60 }" @click="onTimePicked(60)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">1</span>
													<span class="time-bubble-label">{{ $tc('chargerView.hour', 1) }}</span>
													<span class="time-bubble-performance" v-if="selectedCar">{{ calculateKwFromTime(60, 0) }} kWh</span>
												</span>
											</a>
										</div>
									</div>

									<div class="col-lg-3 p-0">
										<div class="time-bubble-row">
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 120 }" @click="onTimePicked(120)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">2</span>
													<span class="time-bubble-label">{{ $tc('chargerView.hours', 2) }}</span>
													<span class="time-bubble-performance" v-if="selectedCar">{{ calculateKwFromTime(120, 0) }} kWh</span>
												</span>
											</a>
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 180 }" @click="onTimePicked(180)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">3</span>
													<span class="time-bubble-label">{{ $tc('chargerView.hours', 3) }}</span>
													<span class="time-bubble-performance" v-if="selectedCar">{{ calculateKwFromTime(180, 0) }} kWh</span>
												</span>
											</a>
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 240 }" @click="onTimePicked(240)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">4</span>
													<span class="time-bubble-label">{{ $tc('chargerView.hours', 4) }}</span>
													<span class="time-bubble-performance" v-if="selectedCar">{{ calculateKwFromTime(240, 0) }} kWh</span>
												</span>
											</a>
										</div>
									</div>

									<div class="col-lg-6 p-0">
										<div class="time-bubble-row">
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 360 }" @click="onTimePicked(360)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">6</span>
													<span class="time-bubble-label">{{ $tc('chargerView.hours', 6) }}</span>
													<span class="time-bubble-performance" v-if="selectedCar">{{ calculateKwFromTime(360, 0) }} kWh</span>
												</span>
											</a>
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 480 }" @click="onTimePicked(480)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">8</span>
													<span class="time-bubble-label">{{ $tc('chargerView.hours', 8) }}</span>
													<span class="time-bubble-performance" v-if="selectedCar">{{ calculateKwFromTime(480, 0) }} kWh</span>
												</span>
											</a>
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 600 }" @click="onTimePicked(600)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">10</span>
													<span class="time-bubble-label">{{ $tc('chargerView.hours', 10) }}</span>
													<span class="time-bubble-performance" v-if="selectedCar">{{ calculateKwFromTime(600, 0) }} kWh</span>
												</span>
											</a>
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 720 }" @click="onTimePicked(720)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">12</span>
													<span class="time-bubble-label">{{ $tc('chargerView.hours', 12) }}</span>
													<span class="time-bubble-performance" v-if="selectedCar">{{ calculateKwFromTime(720, 0) }} kWh</span>
												</span>
											</a>
										</div>
									</div>
								</div>
							</template>

							<template v-if="showAmountPicker && loggedInUserData">
								<a name="step4"></a>

								<div class="form-group">
									<div class="media align-items-center mb-2">
										<span class="step-number">{{ step4 }}</span>
										<div class="media-body" style="max-width: 80%">{{ $t('charger-view.please-choose-amount') }}</div>
									</div>
									<div class="blockquote-footer step-list">
										{{ $t('charger-view.amount-disclaimer-1') }}
									</div>
									<div class="blockquote-footer step-list">
										{{ $t('charger-view.amount-disclaimer-2') }}
									</div>
									<div class="blockquote-footer step-list">
										{{ $t('charger-view.amount-disclaimer-3') }}
									</div>
									<div class="blockquote-footer step-list">
										{{ $t('charger-view.amount-disclaimer-4') }}
									</div>
									<div class="blockquote-footer step-list" v-if="priceSettings.convenienceFeeGross > 0">
										<b>{{ $t('charger-view.amount-disclaimer-5', [priceSettings.convenienceFeeGross]) }}</b>
									</div>
									<div class="blockquote-footer step-list">
										<b>{{ $t('charger-view.amount-disclaimer-6', [priceSettings.unitChargingGross]) }}</b>
									</div>
								</div>

								<div class="mt-2 charger-payment-stripe row">
									<div class="col-lg px-2 py-1">
										<a href="javascript:void(0)" class="charger-payment media align-items-center" :class="{ active: pickedAmount && pickedAmount.amount == 500 }" @click="onAmountPicked(500)">
											<font-awesome-icon icon="ticket-alt" size="2x" class="media mr-2" />
											<span class="media-body">
												<label>500 Ft</label>
												<span v-if="selectedCar">~{{ calculateTimeFromPrice(500) }} {{ $t('charger-view.minute-charge') }}</span>
												<span>~{{ calculateKwFromPrice(500) }} kWh</span>
											</span>
										</a>
									</div>
									<div class="col-lg px-2 py-1">
										<a href="javascript:void(0)" class="charger-payment media align-items-center" :class="{ active: pickedAmount && pickedAmount.amount == 1000 }" @click="onAmountPicked(1000)">
											<font-awesome-icon icon="ticket-alt" size="2x" class="media mr-2" />
											<span class="media-body">
												<label>1.000 Ft</label>
												<span v-if="selectedCar">~{{ calculateTimeFromPrice(1000) }} {{ $t('charger-view.minute-charge') }}</span>
												<span>~{{ calculateKwFromPrice(1000) }} kWh</span>
											</span>
										</a>
									</div>
									<div class="col-lg px-2 py-1">
										<a href="javascript:void(0)" class="charger-payment media align-items-center" :class="{ active: pickedAmount && pickedAmount.amount == 3000 }" @click="onAmountPicked(3000)">
											<font-awesome-icon icon="ticket-alt" size="2x" class="media mr-2" />
											<span class="media-body">
												<label>3.000 Ft</label>
												<span v-if="selectedCar">~{{ calculateTimeFromPrice(3000) }} {{ $t('charger-view.minute-charge') }}</span>
												<span>~{{ calculateKwFromPrice(3000) }} kWh</span>
											</span>
										</a>
									</div>
									<div class="col-lg px-2 py-1">
										<a href="javascript:void(0)" class="charger-payment media align-items-center" :class="{ active: pickedAmount && pickedAmount.amount == 5000 }" @click="onAmountPicked(5000)">
											<font-awesome-icon icon="ticket-alt" size="2x" class="media mr-2" />
											<span class="media-body">
												<label>5.000 Ft</label>
												<span v-if="selectedCar">~{{ calculateTimeFromPrice(5000) }} {{ $t('charger-view.minute-charge') }}</span>
												<span>~{{ calculateKwFromPrice(5000) }} kWh</span>
											</span>
										</a>
									</div>
									<div class="col-lg px-2 py-1">
										<a href="javascript:void(0)" class="charger-payment media align-items-center" :class="{ active: pickedAmount && pickedAmount.amount == 10000 }" @click="onAmountPicked(10000)">
											<font-awesome-icon icon="ticket-alt" size="2x" class="media mr-2" />
											<span class="media-body">
												<label>10.000 Ft</label>
												<span v-if="selectedCar">~{{ calculateTimeFromPrice(10000) }} {{ $t('charger-view.minute-charge') }}</span>
												<span>~{{ calculateKwFromPrice(10000) }} kWh</span>
											</span>
										</a>
									</div>
								</div>
							</template>

							<ChargeFinishTimeTemplate v-if="pickedChargeFinishTime" :msgs="msgs" :price-settings="priceSettings" @onTimePicked="onTimePicked" @clearTimePicker="onShowChargeFinishTimePicker" />

							<ChargeMaxKwTemplate v-if="pickedChargeMaxKW" :msgs="msgs" :priceSettings="priceSettings" @onKwPicked="onKwPicked" @clearKwInputField="onShowChargeMaxKWPicker" />

							<template v-if="pickedAmount && loggedInUserData">
								<a name="step5"></a>

								<div class="form-group">
									<div class="media align-items-center" v-if="!priceSettings.freeCharging">
										<span class="step-number">{{ step5 }}</span>

										<div class="media-body">
											{{ $t('charger-view.start-charge') }}
										</div>
									</div>

									<ParkingAlertPanel :priceSettings="priceSettings" />

									<payment-summary-card v-if="!priceSettings.freeCharging" :data="paymentSummary" :price-settings="priceSettings" />
								</div>

								<ChargeStartArea
									:step="step6"
									:creditCards="creditCards"
									:msgs="msgs"
									:priceSettings="priceSettings"
									:charger="charger"
									:selectedBillingData="selectedBillingData"
									:pickedAmount="pickedAmount"
									:selectedCar="selectedCar"
									:walletBalance="walletBalance"
									:priceToPayNative="priceToPayNative"
								/>
							</template>
						</template>
					</template>
				</template>
				<a name="bottom-anchor"></a>
			</template>
		</div>
		<div v-else-if="showNoChargerInfo">
			<div class="alert alert-warning">
				<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2" />
				{{ $t('charger-view.no-charger-found') }}
			</div>
		</div>
	</div>
</template>

<script>
import jQuery from 'jquery'
import LocationPanelCharger from '@/components/LocationPanelCharger.vue'
import PaymentSummaryCard from '@/components/PaymentSummaryCard.vue'
import * as utils from '@/plugins/utils.js'
import ChargeFinishTimeTemplate from '@/components/ChargeFinishTimeTemplate'
import ChargeMaxKwTemplate from '@/components/ChargeMaxKwTemplate'
import TraitValue from '@/components/TraitValue.vue'
import ChargeStartArea from '@/components/ChargeStartArea.vue'
import CasualChargingSurvey from '@/components/CasualChargingSurvey.vue'
import ParkingAlertPanel from '@/components/ParkingAlertPanel.vue'
import ParkingCodeInput from "@/components/ParkingCodeInput.vue"
import { formatCurrency } from '../assets/functions/currency-formatter'
import BasicDialog from '../components/BasicDialog.vue'

export default {
	name: 'ChargerView',
	data() {
		return {
			priceSettings: null,
			showChargeInProgressError: false,
			charger: null,
			chargerId: null,
			billingDatas: [],
			selectedBillingData: null,
			cars: [],
			creditCards: [],
			selectedCar: null,
			showTimePicker: false,
			showAmountPicker: false,
			pickedAmount: null,
			pickedChargeFinishTime: null,
			pickedChargeMaxKW: null,
			priceToPay: null,
			paymentSummary: null,
			showNoChargerInfo: false,
			showHintForCarAddition: false,
			currentType: 'AC',
			chargerPerformance: 22,
			outlets: [],
			traitDetails: [],
			showInfo: false,
			connectorState: '',
			walletBalance: null,
			connectorStateKey: '',
			payingWithCard: false,
			priceToPayNative: null
		}
	},
	props: {
		msgs: Object,
		app: Object
	},
	asyncComputed: {
		async loggedInUserData() {
			return await this.$store.getters.getLoggedInUserData
		}
	},
	computed: {
		step1() {
			return this.calcSteps(1)
		},
		step2() {
			return this.calcSteps(2)
		},
		step3() {
			return this.calcSteps(3)
		},
		step4() {
			return this.calcSteps(4)
		},
		step5() {
			return this.calcSteps(5)
		},
		step6() {
			return this.calcSteps(6)
		},
		tariffLines() {
			let result = []
			if(this.charger && this.charger.tariffs) {
				for(let t of this.charger.tariffs) {
					if(t.descriptionLines) {
						for(let dl of t.descriptionLines) {
							result.push(dl)
						}
					}
				}
			}
			return result
		},
		connectorStateClass() {
			if(this.connectorStateKey == 'AVAILABLE' || this.connectorStateKey == 'PREPARING') {
				return 'connector-state-available'
			}
			if(this.connectorStateKey == 'OCCUPIED') {
				return 'connector-state-unavailable'
			}
			if(this.connectorStateKey == 'RESERVED' || this.connectorStateKey == 'ERROR' || this.connectorStateKey == 'UNAVAILABLE' || this.connectorStateKey == 'UNKNOWN' || this.connectorStateKey == 'OUT_OF_SERVICE') {
				return 'connector-state-unavailable'
			}
			return 'connector-state-other'
		},
		canStart() {
			if(!this.charger.tariff) {
				return false
			}

			if(this.connectorStateKey === 'AVAILABLE' || this.connectorStateKey === 'PREPARING') {
				return true
			}

			if(this.connectorStateKey === 'FINISHING' && this.charger.deviceType && this.charger.deviceType.canStartNewChargeWhileInFinishingState) {
				return true
			}

			return false
		}
	},
	methods: {
		calcSteps(num) {
			let base = num

			if(!this.billingDatas || this.billingDatas.length < 2) {
				base--
			}

			if(num == 1) {
				return base
			}

			if(!this.cars || this.cars.length < 2) {
				base--
			}

			if(num == 2) {
				return base
			}

			if(!this.priceSettings.freeCharging && (!this.cars || !this.cars.length)) {
				base--
			}

			if(this.priceSettings.freeCharging) {
				base--
			}

			if(num == 6 && this.priceSettings.freeCharging) {
				base--
			}
			
			return base
		},
		toggleInfo() {
			this.showInfo = !this.showInfo
			jQuery('#chargerDetails').collapse(this.showInfo ? 'show' : 'hide')
		},
		carHasChargingPowerInfo() {
			if(this.currentType === 'DC') {
				return this.selectedCar.maxChargingPowerDCKw !== null && typeof this.selectedCar.maxChargingPowerDCKw !== 'undefined'
			}
			else if(this.currentType === 'AC') {
				return this.selectedCar.maxChargingPowerACKw !== null && typeof this.selectedCar.maxChargingPowerACKw !== 'undefined'
			}
		},
		calculateKwFromPrice(price) {
			const kw = (price - this.priceSettings.convenienceFeeGross) / this.priceSettings.unitChargingGross
			return kw.toFixed(2)
		},
		calculateTimeFromPrice(price) {
			if(this.selectedCar) {
				let maxPerformance = this.getMaxPerformance()
				const kw = this.calculateKwFromPrice(price)
				const time = (60 / maxPerformance) * kw
				return Math.floor(time)
			}
		},
		getMaxPerformance() {
			let currentType = 'AC'
			let carPerformance = this.selectedCar.maxChargingPowerACKw
			if(currentType === 'DC') {
				carPerformance = this.selectedCar.maxChargingPowerDCKw
			}
			return Math.min(this.chargerPerformance, carPerformance)
		},
		calculateKwFromTime(time, decimals) {
			if(this.selectedCar) {
				let maxPerformance = this.getMaxPerformance()

				const kw = (maxPerformance / 60) * time
				return kw.toFixed(decimals)
			}
		},
		getWalletBalance() {
			this.$rest.getWalletBalance(responseData => {
				if(responseData.result) {
					this.walletBalance = responseData.data.ballance
				}
			})
		},
		loadChargerId() {
			const cId = this.$route.query
			if(this.$route.query.chargerId) {
				this.chargerId = this.$route.query.chargerId
			}
			else {
				this.chargerId = this.$route.params.chargerId
			}
		},
		async onLoad() {
			let loggedInUserData = await this.$store.getters.getLoggedInUserData
			if(loggedInUserData) {
				this.getWalletBalance()

				this.$rest.getCreditCards(data => {
					this.creditCards = data
					this.creditCards = this.creditCards.filter(card => {
						if(card.cardMask) return card
					})
				})
			}

			if(this.chargerId) {
				this.$rest.getCharger(
					this.chargerId,
					responseData => {
						this.charger = responseData
						this.showStartCharging()
						this.getChargerProperties()
					},
					error => {
						this.showNoChargerInfo = true
					}
				)

				await this.getPriceSettings(this.chargerId)
			}
			else {
				alert(this.$t('casualSurvey.somethingWentWrong'))
				this.$router.push({ path: '/charger-search/charger' })
			}
		},
		async getPriceSettings(chargerId) {
			await this.$rest.getPriceSettings(chargerId, response => {
				this.priceSettings = response
			})
		},
		getChargerProperties() {
			this.currentType = this.charger.deviceType && this.charger.deviceType.currentType ? this.charger.deviceType.currentType : 'AC'

			this.traitDetails = []

			for(let trait of this.charger.staticTraits) {
				if(trait.trait.identifier === 'outlets') {
					this.outlets = trait.listItems
				}
				else if(trait.trait.identifier === 'performance') {
					this.chargerPerformance = trait.numericValue
				}

				if(trait.trait.major) {
					this.traitDetails.push(trait)
				}
			}

			if(this.charger.status) {
				this.connectorStateKey = this.charger.status.value
				this.connectorState = this.charger.status.title
			}

			this.traitDetails = this.traitDetails.filter(tv => !this.isTraitValueEmpty(tv))
			this.traitDetails.sort((tv1, tv2) => tv1.trait.orderNumber - tv2.trait.orderNumber)
		},
		isTraitValueEmpty(tv) {
			if(!tv || !tv.trait || !tv.trait.type) {
				return true
			}
			if(tv.trait.type == 'TEXT' && !tv.value) {
				return true
			}
			if(tv.trait.type == 'LIST_SINGLE' && !tv.listItem) {
				return true
			}
			if(tv.trait.type == 'LIST_MULTIPLE' && !tv.listItems) {
				return true
			}
			if(tv.trait.type == 'LIST_CUSTOM' && !tv.listStrings) {
				return true
			}
			return false
		},
		showStartCharging() {
			if(this.loggedInUserData) {
				this.$rest.listBillingDataForCurrentUser(responseData => {
					this.billingDatas = responseData ? responseData : []

					this.selectedBillingData = null

					for(var i = 0; i < this.billingDatas.length; i++) {
						this.selectedBillingData = this.billingDatas[i]
						if(this.selectedBillingData.defaultBillingData) {
							break
						}
					}
					this.showStartChargingDialog = true
				})

				this.$rest.loadMyCars(responseData => {
					this.cars = responseData
					for(let car of this.cars) {
						if(car.defaultCar === true) {
							this.selectedCar = car
						}
					}
					if(this.cars.length == 0 && this.$store.getters.getSettings.wizardCarsOptional && !this.priceSettings.freeCharging) {
						this.showAmountPicker = true
					}
				})
			}
		},
		onShowTimePicker() {
			if(!this.carHasChargingPowerInfo()) {
				this.showHintForCarAddition = true
			}
			else {
				this.showTimePicker = true
				this.showHintForCarAddition = false
				utils.scrollToAnchor('step4')
			}
			this.showAmountPicker = false
			this.pickedAmount = null
			this.pickedChargeFinishTime = false
			this.pickedChargeMaxKW = false
		},
		onShowAmountPicker() {
			this.showHintForCarAddition = false
			this.showTimePicker = false
			this.showAmountPicker = true
			this.pickedAmount = null
			this.pickedChargeFinishTime = false
			this.pickedChargeMaxKW = false
			utils.scrollToAnchor('step4')
		},
		onShowChargeFinishTimePicker() {
			if(!this.carHasChargingPowerInfo()) {
				this.showHintForCarAddition = true
				this.pickedChargeFinishTime = false
				this.pickedChargeMaxKW = false
				this.showAmountPicker = false
			}
			else {
				this.showHintForCarAddition = false
				this.showTimePicker = false
				this.showAmountPicker = false
				this.pickedAmount = null
				this.pickedChargeFinishTime = true
				this.pickedChargeMaxKW = false
				utils.scrollToAnchor('step4')
			}
		},
		onShowChargeMaxKWPicker() {
			this.showHintForCarAddition = false
			this.showTimePicker = false
			this.showAmountPicker = false
			this.pickedAmount = null
			this.pickedChargeFinishTime = false
			this.pickedChargeMaxKW = true
			utils.scrollToAnchor('step4')
		},
		onScrollDown() {
			utils.scrollToAnchor('bottom-anchor')
		},
		async onTimePicked(time) {
			if(time <= 0) {
				this.paymentSummary = null
				this.pickedAmount = null
				return
			}
			const kw = this.calculateKwFromTime(time, 2)
			await this.setPriceToPayFromBackend(time)
			this.paymentSummary = {
				reservedEnergy: kw,
				time: time,
				amount: this.priceToPay
			}
			this.pickedAmount = { time: time }
			utils.scrollToAnchor('step5')
		},
		async onKwPicked(kw) {
			if(!kw) {
				this.pickedAmount = null
				this.paymentSummary = null
				return
			}
			const maxPerformance = this.getMaxPerformance()
			const time = ((60 / maxPerformance) * kw).toFixed(0)
			await this.setPriceToPayFromBackend(time)
			this.paymentSummary = {
				reservedEnergy: kw,
				time: time,
				amount: this.priceToPay
			}
			this.pickedAmount = { time: time }
			utils.scrollToAnchor('step5')
		},
		onAmountPicked(amount) {
			this.pickedAmount = { amount: amount }
			this.priceToPayNative = amount
			this.priceToPay = Intl.NumberFormat().format(amount)
			this.paymentSummary = {
				reservedEnergy: this.calculateKwFromPrice(amount),
				time: this.calculateTimeFromPrice(amount),
				amount: amount
			}
			utils.scrollToAnchor('step5')
		},
		async setPriceToPayFromBackend(time) {
			if(!this.selectedCar) {
				return
			}
			await this.$rest.getGrossToPay(
				this.charger.id,
				this.selectedCar.id,
				time,
				grossToPay => {
					this.priceToPayNative = grossToPay
					this.priceToPay = grossToPay
				},
				error => {}
			)
		},
		stopCharging() {
			this.$rest.stopCharging(this.charger.id, responseData => {})
		}
	},
	mounted() {
		this.loadChargerId()
		this.onLoad()
	},
	watch: {
		//this seems to be not working
		$route(to, from) {
			if(to.name === 'charger') {
				this.onLoad()
			}
		},
		loggedInUserData(newValue, oldValue) {
			if(!newValue) {
				this.$router.push({ name: 'login' })
			}
		}
	},
	components: {
		ParkingCodeInput,
		ChargeMaxKwTemplate,
		ChargeFinishTimeTemplate,
		LocationPanelCharger,
		PaymentSummaryCard,
		TraitValue,
		ChargeStartArea,
		CasualChargingSurvey,
		ParkingAlertPanel,
		BasicDialog
	}
}
</script>

<style>
.step-number {
	font-size: 1.8rem;
	font-weight: bold;
	margin-right: 1rem;
	border-radius: 50%;
	border: 1px solid #333333;
	color: #ffffff;
	background-color: #509cd5;
	width: 2.7rem;
	height: 2.7rem;
	line-height: 2.5rem;
	text-align: center;
	display: inline-block;
	pointer-events: none;
	vertical-align: middle;
	position: relative;
	padding-right: 0.2rem;
}

.step-number:after {
	content: '.';
	position: absolute;
}

.step-list {
	margin-left: 1rem;
}

.time-bubble-row {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	position: relative;
	margin: 0.25rem 0;
}

.time-bubble-row:before {
	content: '';
	display: block;
	border-top: 1px solid #333333;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	transform: translateY(50%);
}

.time-bubble {
	display: inline-block;
	border: 1px solid #333333;
	min-width: 65px;
	height: 65px;
	text-align: center;
	border-radius: 50%;
	font-size: 0.9rem;
	line-height: 0.9rem;
	position: relative;
	overflow: hidden;
	background-color: #ffffff;
}

.time-bubble-inner {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
}

.time-bubble-value {
	display: block;
	margin: auto;
	padding-bottom: 2rem;
	font-size: 1.2rem;
	font-weight: bold;
	color: #509cd5;
}

.time-bubble-label {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding-bottom: 1.4rem;
	background-color: rgba(0, 0, 0, 0.75);
	color: #ffffff;
	font-size: 0.7rem;
}

.time-bubble-performance {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding-bottom: 0.3rem;
	background-color: white;
	color: black;
	font-weight: bold;
	font-size: 0.6rem;
}

.time-bubble:hover,
.time-bubble:active,
.time-bubble.active {
	background-color: #509cd5;
	transform: translateY(-2px);
}

.time-bubble:hover .time-bubble-value,
.time-bubble:active .time-bubble-value,
.time-bubble.active .time-bubble-value {
	color: #ffffff;
	transform: translateY(-4px);
	font-size: 1.4rem;
}

.charger-limit-type {
	border: 1px solid #333333;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	width: 100%;
	color: #333333;
	height: 100%;
}

.charger-limit-type > * {
	margin-top: auto;
	margin-bottom: auto;
}

.charger-limit-type:hover,
.charger-limit-type:active,
.charger-limit-type.active {
	color: #ffffff;
	text-decoration: none;
	background-color: #509cd5;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	transform: translateY(-2px);
}

.charger-payment-stripe {
	padding: 15px;
}

.charger-payment {
	border: 1px solid #333333;
	padding: 15px;
	color: #333333;
	background-color: rgba(255, 255, 255, 0.1);
}

a.charger-payment:hover,
a.charger-payment:active,
a.charger-payment.active {
	color: #ffffff;
	text-decoration: none;
	background-color: #509cd5;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	transform: translateY(-2px);
}

.charger-payment > span.media-body {
	margin-left: 0.5rem;
}

.charger-payment > span.media-body > label {
	margin-bottom: 0;
	font-weight: bold;
	font-size: 1.1rem;
}

.charger-payment > span.media-body > span {
	display: block;
	font-size: 0.9rem;
}

.amount-to-pay {
	width: auto;
	display: inline-block;
	color: #ffffff;
	background-color: #28a745;
	font-size: 1.8rem;
	font-weight: bold;
	font-family: -apple-system;
	border-radius: 10px;
	margin: 10px 10px 20px 10px;
	text-align: center;
	padding: 0px 15px;
	height: 43px;
	box-shadow: grey 2px 2px;
}

.charger-info-toggle-button-2 {
	width: 100%;
	border: none;
	background: transparent;
	transform: translateY(8px);
}

@keyframes animated-down-icon-keyframes {
	from {
		transform: translateY(-2px);
	}
	to {
		transform: translateY(2px);
	}
}

.scroll-down-message {
	background-color: #08b647;
	color: #ffffff;
	padding: 0.25rem 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	margin: 0 auto 0.5rem auto;
	-webkit-animation: animated-down-icon-keyframes 1.0s infinite linear;
	animation: animated-down-icon-keyframes 1.0s infinite linear;
	text-align: center;
	max-width: 290px;
	border: none;
}

.animated-down-icon {
	-webkit-animation: animated-down-icon-keyframes 0.5s infinite linear;
	animation: animated-down-icon-keyframes 0.5s infinite linear;
}
</style>
