<template>
	<div>
		<h1 class="receipt-title">{{ $t('pageTitle.receipt') }}</h1>
		<div class="pointer_container_center">
			<div class="pointer_text_container pointer_text_container-orange">
				<div class="pointer_key pointer_key-orange">
					{{ $t('receipt-view.id') }}
				</div>
				<div class="pointer_value">
					{{ receipt.rowNumber }}
				</div>
			</div>

			<div class="pointer_text_container pointer_text_container-blue">
				<div class="pointer_key pointer_key-blue">
					{{ $t('receipt-view.kwh') }}
				</div>
				<div class="pointer_value">
					{{ receipt.consumedEnergyInKW }} kWh
				</div>
			</div>

			<div class="pointer_text_container pointer_text_container-orange">
				<div class="pointer_key pointer_key-orange">
					{{ $t('receipt-view.issue-date') }}
				</div>
				<div class="pointer_value">
					{{ receipt.issueDate }}
				</div>
			</div>

			<div class="pointer_text_container pointer_text_container-blue">
				<div class="pointer_key pointer_key-blue">
					{{ $t('receipt-view.cost') }}
				</div>
				<div class="pointer_value">
					{{ receipt.grossPrice }}
				</div>
			</div>
		</div>
		<div class="center-container">
			<button v-on:click="startPayment" class="btn btn-lg btn-success">{{ $t('receipt-view.pay') }}</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ReceiptView',
	data() {
		return {
			rowNumber: '',
			receipt: {
				consumedEnergyInKW: null,
				rowNumber: null,
				issueDate: null,
				grossPrice: null
			}
		}
	},
	methods: {
		loadReceipt: function () {
			if(this.rowNumber) {
				this.$rest.loadReceipt(this.rowNumber,
					receipt => {
						this.receipt = receipt
						this.receipt.issueDate = new Date(this.receipt.issueDate).toLocaleString()
					},
					error => {
						alert(error.responseCode)
					}
				)
			}
			else {
				alert(this.$t('receipt-view.error-no-receipt-rownumber'))
			}
		},
		startPayment: function () {
			this.$rest.startPayment(this.receipt.rowNumber,
				url => window.location.href = url,
				error => alert(error.responseCode)
			)
		},
		parseRowNumberFromUrl: function () {
			this.rowNumber = this.$route.query.rowNumber
		}
	},
	beforeMount() {
		this.parseRowNumberFromUrl()
		this.loadReceipt()
	}
}

</script>

<style>
.receipt-title {
  font-size: 3rem;
  color: #5ab8eb;
  margin: auto;
  padding-bottom: 2rem;
  text-align: center !important;
  position: relative;
}

.center-container {
  display: flex;
  justify-content: center;
  margin: 5px;
}

.pointer_container_center {
  margin: auto;
  width: 60%;
  padding: 10px;
}

.pointer_text_container {
  border: 3px solid;
  padding: 2px;
  border-radius: 5px;
  display: flex;
  min-width: 500px;
}

.pointer_text_container-blue {
  border-color: blue;
}

.pointer_text_container-orange {
  border-color: orange;
}

.pointer_key {
  height: 40px;
  position: relative;
  display: flex;
  padding-left: 10px;
  padding-right: 5px;
  font-weight: bold;
  align-items: center;
  flex-direction: row;
  color: white;
  width: 250px;
}

.pointer_key::before {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #FF8C00;
}

.pointer_value {
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-weight: bold;
}

.pointer_key-blue {
  background: blue;
}

.pointer_key-blue::before {
  border-left: 20px solid blue;
}

.pointer_key-orange {
  background: #FF8C00;
}

.pointer_key-orange::before {
  border-left: 20px solid #FF8C00;
}
</style>
