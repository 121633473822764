<template>
	<div class="charging-survey mt-3 d-flex flex-column align-items-center">
		<div :class="panelsOpened ? 'row openedPanels' : 'closedPanels'">
			<div @click="!panelsOpened ? openNoLoggedInPanel('login') : null" class="d-flex order-1 order-md-2" :class="{ 'col-md-6 pl-md-2': panelsOpened, 'col-12 p-0': !panelsOpened }" style="z-index: 1">
				<a name="panelLogin"></a>
				<div class="login-nologin-item">
					<div class="item-title item-title-login">
						<div class="my-auto">
							<i class="pi pi-sign-in" style="font-size: 1.6rem"></i>
							<h3 class="py-2">
								{{ $t('charger-view.start-charge-with-login') }}
							</h3>
						</div>
					</div>
					<div v-if="panelsOpened">
						<div class="mt-3">
							<PrimeButton @click="navigate('/login')" :style="{ 'background-color': 'var(--newbtn-blue)', 'min-width': '170px' }" class="d-flex gap-2 mx-auto mb-3 p-button-rounded p-button-raised">
								<div :style="{ margin: '0 auto' }">
									<i class="pi pi-sign-in mr-1"></i>
									<span>{{ $t('login.loginButton') }}</span>
								</div>
							</PrimeButton>

							<div class="mx-auto flex-column" :style="{ 'max-width': '300px' }">
								<p class="mb-0">{{ $t('charger-view.no-account') }}</p>
								<PrimeButton @click="navigate('/register')" :style="{ 'border-radius': '30px', 'min-height': '45px', 'min-width': '170px', padding: '0px 20px', 'background-color': '#fde047', color: 'black', border: 'none' }" class="new-btn p-button-raised">
									<div :style="{ margin: '0 auto' }">
										<i class="pi pi-user mr-1"></i>
										<span>{{ $t('charger-view.register') }}</span>
									</div>
								</PrimeButton>
							</div>
						</div>
						<ul class="text-left list-unstyled my-3 d-flex flex-column pl-2" :style="{ width: '90%', gap: '5px' }">
							<li class="w-full d-flex align-items-center" :style="{ gap: '10px' }">
								<i class="pi pi-check-circle" style="color: green"></i>
								<p class="mb-0">{{ $t('charger-view.receipt') }}</p>
							</li>
							<li class="w-full d-flex align-items-center" :style="{ gap: '10px' }">
								<i class="pi pi-check-circle" style="color: green"></i>
								<p class="mb-0">
									{{ $t('charger-view.charging-history') }}
								</p>
							</li>
							<li class="w-full d-flex align-items-center" :style="{ gap: '10px' }">
								<i class="pi pi-check-circle" style="color: green"></i>
								<p class="mb-0">
									{{ $t('charger-view.all-possible-amounts') }}
									<!-- <small>felhasználható a Kuponok menüpontban</small> -->
								</p>
							</li>
							<li class="w-full d-flex align-items-center" :style="{ gap: '10px' }">
								<i class="pi pi-check-circle" style="color: green"></i>
								<p class="mb-0">
									{{ $t('charger-view.charge-by-time') }}
								</p>
							</li>
							<li class="w-full d-flex align-items-center" :style="{ gap: '10px' }">
								<i class="pi pi-check-circle" style="color: green"></i>
								<p class="mb-0">
									{{ $t('charger-view.collect-coupon') }}
								</p>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div @click="!panelsOpened ? openNoLoggedInPanel('chargestart') : null" class="d-flex order-2 order-md-1" :class="{ 'col-md-6 pr-md-2': panelsOpened, 'col-12 p-0': !panelsOpened }">
				<a name="panelCasual"></a>
				<div class="login-nologin-item">
					<div class="item-title item-title-casual">
						<div class="my-auto">
							<i class="pi pi-bolt" style="font-size: 2rem"></i>
							<h3 class="py-2">
								{{ $t('charger-view.start-charge-without-login') }}
							</h3>
						</div>
					</div>
					<div v-if="panelsOpened">
						<div class="mt-5">
							<PrimeButton @click="selectOnetimeCharge()" :style="{ 'background-color': 'var(--mode-color)', border: 'none' }" class="d-flex gap-2 mx-auto mb-3 p-button-rounded p-button-raised">
								<i class="pi pi-bolt"></i>
								<span>{{ $t('casual-charging.buttonStart') }}</span>
							</PrimeButton>
						</div>
						<ul class="text-left list-unstyled my-3 d-flex flex-column pl-2" :style="{ width: '90%', gap: '10px' }">
							<li class="w-full d-flex align-items-center" :style="{ gap: '10px' }">
								<i class="pi pi-info-circle" style="color: var(--sky-500)"></i>
								<div>
									<p class="mb-0">{{ $t('charger-view.least-5000') }}</p>
									<small class="text-center">{{ $t('charger-view.least-5000-small') }}</small>
								</div>
							</li>
							<li class="w-full d-flex align-items-center" :style="{ gap: '10px' }">
								<i class="pi pi-check-circle" style="color: green"></i>
								<p class="mb-0">{{ $t('charger-view.e-receipt') }}</p>
							</li>
							<li class="w-full d-flex align-items-center" :style="{ gap: '10px' }">
								<i class="pi pi-ban" style="color: red"></i>
								<p class="mb-0">{{ $t('charger-view.no-receipt') }}</p>
							</li>
							<li class="w-full d-flex align-items-center" :style="{ gap: '10px' }">
								<i class="pi pi-ban" style="color: red"></i>
								<p class="mb-0">
									{{ $t('charger-view.no-collect-coupon') }}
									<!-- Nem gyűjtessz kuponpontokat a töltésed után -->
									<!-- <small>felhasználható a Kuponok menüpontban</small> -->
								</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="form-group text-center mt-2" v-if="onetimeCharge">
			<ParkingAlertPanel :priceSettings="priceSettings" />

			<div v-if="!priceSettings.freeCharging">
				<div>
					<PaymentSummaryCard v-if="!showOtherOptions" :data="paymentSummary" :price-settings="priceSettings" :showOtherOptionsBtn="true" :otherOptionsBtnText="otherOptionsBtnText" @otherOptionsClick="otherOptionsClick" />
					<div v-else class="amounts-container">
						<h5>{{ $t('charger-view.choose-other-amount') }}</h5>
						<div class="other-amounts-container">
							<div @click="selectAmount(amount)" v-for="amount in casualAmounts" :key="amount" :class="selectedAmount === amount ? 'selected-amount' : 'other-amounts'" style="gap: 10px">
								{{ new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF', maximumSignificantDigits: 3 }).format(amount) }}
							</div>
						</div>
					</div>
				</div>
				<div class="form-group text-left d-flex flex-column mb-3" :style="{ 'max-width': '90%', margin: '0 auto', gap: '5px' }">
					<div class="blockquote-footer step-list">
						{{ $t('charger-view.amount-disclaimer-1') }}
					</div>
					<div class="blockquote-footer step-list">
						{{ $t('charger-view.amount-disclaimer-2') }}
					</div>
					<div class="blockquote-footer step-list">
						{{ $t('charger-view.amount-disclaimer-3') }}
					</div>
					<div class="blockquote-footer step-list">
						{{ $t('charger-view.amount-disclaimer-4') }}
					</div>
					<div class="blockquote-footer step-list" v-if="priceSettings.convenienceFeeGross > 0">
						<b>{{ $t('charger-view.amount-disclaimer-5', [priceSettings.convenienceFeeGross]) }}</b>
					</div>
					<div class="blockquote-footer step-list">
						<b>{{ $t('charger-view.amount-disclaimer-6', [priceSettings.unitChargingGross]) }}</b>
					</div>
				</div>
			</div>

			<ParkingCodeInput class="my-3" @code="($e) => (parkCode = $e)" v-if="charger.locationHasParking" style="margin-left: -21px; margin-right: -21px" />

			<!-- <EmailInput id="email" v-model="email" :msgs="msgs" class="form-control email-input" maxlength="255" :placeholder="$t('casualSurvey.email')" :required="true" /> -->
			<div :style="{ 'max-width': '90%', margin: '0 auto' }">
				<div class="email-pass-container" :style="{ 'max-width': '400px', margin: '0 auto' }">
					<label :style="{ 'font-weight': 'normal' }">{{ $t('casualSurvey.addEmailAddress') }}</label>
					<input placeholder="Email" :style="{ border: '1px solid green' }" type="text" v-model="email" class="form-control" autocorrect="off" autocapitalize="none" />
				</div>
				<ComponentMessage :msgs="msgs" forComponent="email" />

				<a name="step3"></a>

				<PrimeButton @click="sendEmail()" :style="{ 'background-color': 'var(--mode-color)', 'min-width': '270px', border: 'none' }" class="d-flex gap-2 mx-auto m-3 p-button-rounded p-button-raised">
					<div :style="{ margin: '0 auto' }">
						<i class="pi pi-bolt"></i>
						{{ $t('casual-charging.buttonStart') }}
					</div>
				</PrimeButton>
			</div>
		</div>
	</div>
</template>

<script>
import * as utils from '@/plugins/utils.js';
import ParkingAlertPanel from '@/components/ParkingAlertPanel.vue';
import PaymentSummaryCard from '@/components/PaymentSummaryCard.vue';
import ParkingCodeInput from '@/components/ParkingCodeInput.vue';

export default {
	name: 'CasualChargingSurvey',
	props: {
		msgs: Object,
		priceSettings: Object,
		charger: Object
	},
	data() {
		return {
			app: null,
			collectPointsAnswered: false,
			collectPoints: null,
			gettingInvoicePointsAnswered: false,
			gettingInvoice: null,
			chargerId: null,
			email: null,
			parkCode: '',
			paymentSummary: {
				reservedEnergy: this.calculateKwFromPrice(5000),
				time: null,
				amount: 5000
			},
			onetimeCharge: false,
			panelsOpened: false,
			showOtherOptions: false,
			otherOptionsBtnText: 'more', //more, less or other are valid values
			selectedAmount: 5000,
			casualAmounts: [5000, 10000, 15000]
		};
	},
	components: {
		ParkingCodeInput,
		ParkingAlertPanel,
		PaymentSummaryCard
	},
	methods: {
		navigate(path) {
			this.$router.push(path);
		},
		openNoLoggedInPanel(choosedCard) {
			this.panelsOpened = true;

			//scrolling
			if (choosedCard === 'login') {
				utils.scrollToAnchor('panelLogin', 20);
			}
			if (choosedCard === 'chargestart') {
				utils.scrollToAnchor('panelCasual', 20);
			}
		},
		checkScreenSize() {
			this.panelsOpened = window.innerWidth >= 768;
		},
		onCode(code) {
			this.parkCode = code;
		},
		selectOnetimeCharge() {
			this.onetimeCharge = true;
			this.scrollToBottom();
		},
		scrollToBottom() {
			setTimeout(() => {
				window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
			}, 0);
		},
		setGettingInvoice(wantToGetInvoice) {
			this.gettingInvoice = wantToGetInvoice;
			this.gettingInvoicePointsAnswered = true;
			if (wantToGetInvoice) {
				this.$router.push({ name: 'register', arams: { chargerId: this.chargerId } });
			} else {
				utils.scrollToAnchor('step3');
			}
		},
		sendEmail() {
			this.$rest.startCasualCharging(
				this.chargerId,
				this.selectedAmount,
				this.email,
				this.parkCode,
				(responseData) => {
					if (responseData.result) {
						if (responseData.extraData) {
							localStorage.setItem('loggedInUserId', responseData.extraData.data.user.id);
							localStorage.setItem('loggedInEmail', responseData.extraData.data.user.email);
							localStorage.setItem('loggedInUserToken', responseData.extraData.data.token);
							this.app.setLoggedInUserData(responseData.extraData.data.user);
						}
						if (responseData.data) {
							window.location.href = responseData.data;
						} else {
							this.$router.push('/charge-history');
						}
					}
				},
				(error) => {
					if (error && error.response && error.response.data) {
						this.showErrorMessage(error);
					} else {
						alert(this.$t('casual-charging.errorStart'));
					}
				}
			);
		},
		showErrorMessage(error) {
			if (error.response.data.responseCode === 'CHARGER_NOT_ACTIVE') {
				alert(this.$t('charger-view.charger-not-available'));
			} else if (error.response.data.responseCode === 'SIMPLEPAY_INVALID_PASSWORD') {
				alert(this.$t('charger-view.wrong-secret-code'));
			} else if (error.response.data.responseCode === 'NO_ACTIVE_TARIFF') {
				alert(this.$t('charger-view.no-tariff'));
			} else {
				alert(this.$t('charger-view.unsuccessful-start'));
			}
		},
		calculateKwFromPrice(price) {
			const kw = (price - this.priceSettings.convenienceFeeGross) / this.priceSettings.unitChargingGross;
			return kw.toFixed(2);
		},
		otherOptionsClick() {
			this.showOtherOptions = true;
		},
		selectAmount(amount) {
			this.showOtherOptions = false;
			this.selectedAmount = amount;
			this.paymentSummary.amount = amount;
			this.paymentSummary.reservedEnergy = this.calculateKwFromPrice(amount);

			if (amount === this.casualAmounts[0]) {
				this.otherOptionsBtnText = 'more';
			} else if (amount === this.casualAmounts[this.casualAmounts.length - 1]) {
				this.otherOptionsBtnText = 'less';
			} else {
				this.otherOptionsBtnText = 'other';
			}
		}
	},
	created() {
		this.app = this.$root.$children[0];
		this.chargerId = this.$route.params.chargerId;
	},
	beforeMount() {
		this.checkScreenSize();
	}
};
</script>

<style scoped>
.charging-survey {
	padding: 0;
}

.btn-inactive {
	opacity: 0.5;
}

.email-input {
	border: 2px solid var(--mode-color);
	background-color: #c2edd2;
	max-width: 320px;
	margin: auto;
}

.email-input::placeholder {
	color: #0b6b2e;
	opacity: 0.75;
}

.email-input:-ms-input-placeholder {
	color: #0b6b2e;
}

.email-input::-ms-input-placeholder {
	color: #0b6b2e;
}

.openedPanels {
	max-width: 768px;
}

.closedPanels {
	justify-content: center;
	flex-wrap: wrap;
	padding: 0;
	border-radius: 10px;
	border: 1px solid rgb(225, 225, 225);
	width: 100%;
	max-width: 768px;
}

.login-nologin-item {
	align-items: center;
	text-align: center;
	min-width: 270px;
	width: 100%;
	border-radius: 6px;
	border: 1px solid rgb(225, 225, 225);
	margin-bottom: 0.5rem;
}

.closedPanels .login-nologin-item {
	border: none;
	margin-bottom: 0;
}

.login-nologin-item .item-title {
	color: #ffffff;
	padding: 35px 15px 50px 15px;
	height: 200px;
	display: flex;
	flex-direction: column;
	place-items: center;
	-webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.login-nologin-item .item-title.item-title-login {
	background-color: var(--active);
	background: linear-gradient(to bottom left, #32a0ea, #5e7ef2);
	border-radius: 100% 0% 100% 0% / 0% 50% 50% 100%;
	z-index: 1;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.login-nologin-item .item-title.item-title-casual {
	background-color: var(--mode-color);
	color: white;
	padding: 35px 10px 50px 10px;
	height: 200px;
	background: linear-gradient(to bottom left, var(--nologged-in-card1), var(--nologged-in-card2));
	display: flex;
	flex-direction: column;
	place-items: center;
	border-radius: 100% 0% 100% 0% / 0% 50% 50% 100%;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.closedPanels .login-nologin-item .item-title.item-title-casual {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	margin-top: -110px;
	padding-top: 130px;
	min-height: 260px;
}

.login-nologin-item h3 {
	margin: 0;
	font-weight: normal;
	font-size: 1.5rem;
	color: rgb(250, 250, 250);
}

.amounts-container {
	min-height: 150px;
	/* 	box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
 */
	border: 1px solid #dfdfdf;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 3px;
	width: 100%;
	gap: 6px;
}

.other-amounts-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.other-amounts {
	border: 1px solid var(--mode-color);
	color: var(--mode-color);
	padding: 8px 20px;
	font-size: 18px;
	border-radius: 30px;
	cursor: pointer;
}

.selected-amount {
	border: 1px solid var(--mode-color);
	color: var(--mode-color);
	padding: 8px 20px;
	font-size: 18px;
	border-radius: 30px;
	cursor: pointer;
	background-color: var(--mode-color);
	color: white;
}
</style>

<style>
.card {
	background: transparent;
}

@media screen and(min-width: 450px) {
	.charging-survey {
		padding: 1rem;
	}

	.login-nologin {
		padding: 20px;
	}
}
</style>
