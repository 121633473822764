<template>
	<div>
		<div v-if="!editedBillingData">
			<table class="table table-striped" v-if="billingDatas.length">
				<thead>
					<tr>
						<th>{{ $t('billingDatas.listHeader') }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="billingData in billingDatas" :key="billingData.id">
						<td class="maxWidth">
							<div class="float-right ml-3 buttonRow">
								<button class="btn btn-primary" @click="modifyBillingData(billingData)">
									<font-awesome-icon icon="pencil-alt" />
									{{ $t('billingDatas.modify') }}
								</button>
								<button class="btn btn-danger" @click="deleteBillingData(billingData)">
									<font-awesome-icon icon="trash" />
									{{ $t('billingDatas.delete') }}
								</button>
							</div>
							<b>{{ billingData.payerName }}</b>
							<span class="ml-2 text-danger" v-if="billingData.defaultBillingData">
								{{ $t('billingDatas.defaultBillingData') }}
							</span>
							<div v-if="billingData.type == 'COMPANY'" class="billing-secondary-data">{{ $t('billingDatas.taxNumber') }}: {{ billingData.taxNumber }}</div>
							<div class="billing-secondary-data">{{ $t('billingDatas.add') }}: {{ billingData.address.full }}</div>
						</td>
					</tr>
				</tbody>
			</table>
			<div class="text-center">
				<button @click="createNewBillingData" class="btn btn-primary">
					<font-awesome-icon icon="plus" />
					{{ $t('billingDatas.buttonAdd') }}
				</button>
			</div>
		</div>

		<BillingDataEditor
			:billingData="editedBillingData"
			:shouldBeDefault="billingDatas.length == 0"
			:loggedInUserData="loggedInUserData"
			:msgs="msgs"
			@save="saveBillingData"
			@cancel="cancelEditedBillingData"
			v-if="editedBillingData"
		/>
	</div>
</template>

<script>
import Vue from 'vue'
import BillingDataEditor from '@/components/BillingDataEditor.vue'

export default {
	name: 'BillingDataView',
	data() {
		return {
			billingDatas: [],
			editedBillingData: null
		}
	},
	props: {
		loggedInUserData: Object,
		msgs: Object
	},
	methods: {
		createNewBillingData() {
			this.editedBillingData = { address: {} }
		},
		saveBillingData() {
			this.$rest.saveBillingDataForCurrentUser(this.editedBillingData, responseData => {
				this.billingDatas = responseData ? responseData : []
				this.cancelEditedBillingData()
				this.$emit('onSaved')
			})
		},
		deleteBillingData(billingData) {
			if(confirm(this.$t('billingDatas.confirmDelete'))) {
				this.$rest.deleteBillingDataForCurrentUser(billingData.id, responseData => {
					this.billingDatas = responseData ? responseData : []
					this.cancelEditedBillingData()
				})
			}
		},
		modifyBillingData(billingData) {
			this.editedBillingData = billingData
		},
		cancelEditedBillingData() {
			this.editedBillingData = null
			this.loadBillingDatas()
		},
		loadBillingDatas() {
			this.$rest.listBillingDataForCurrentUser(responseData => {
				this.billingDatas = responseData ? responseData : []
			})
		}
	},
	mounted() {
		this.loadBillingDatas()
	},
	components: {
		BillingDataEditor
	},
	emits: ['onSaved']
}
</script>

<style>
.billing-secondary-data {
	font-size: 13px;
}
</style>
