<template>

	<form-wizard
		title=""
		subtitle=""
		shape=""
	>
		<a @click="changeTab" class="fake-modal-cancel-button active" :title="cancelBtnTitle">
			<font-awesome-icon icon="window-close"/>
		</a>


		<tab-content ttitle="register existing" icon="ti-check">

			<h3>{{ $t('cars.registerYourCar') }}</h3>

			<div class="alert alert-warning">
				<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2"/>
				{{ $t('cars.chooseVehicleMsg') }}
			</div>

			<div class="row vehicle-row">
				<div class="col-sm-6 form-group">
					<label for="vehicles">{{ $t('cars.vehicles') }}: *</label>
					<span class="p-fluid">
						<AutoComplete
							id="vehicle"
							:msgs="msgs"
							style="max-height: 53%"
							maxlength="255"
							:required="true"
							:dropdown="true"
							v-model="selectedVehicle"
							:suggestions="filteredVehicles"
							:forceSelection="true"
							:autoHighlight="true"
							@complete="searchVehicles($event)"
							field="name"
						/>
						<ComponentMessage :msgs="msgs" forComponent="vehicle"/>
					</span>
					<ComponentMessage :msgs="msgs" forComponent="vehicles"/>
				</div>
				<div class="col-sm-6 form-group">
					<label for="licencePlate">{{ $t('cars.licencePlate') }}: *</label>
					<TextInput
						id="licencePlate"
						v-model="licencePlate"
						:msgs="msgs"
						class="form-control"
						maxlength="20"
						:required="true"
					/>
					<ComponentMessage :msgs="msgs" forComponent="licencePlate"/>
				</div>
			</div>
			<div class="row vehicle-row">
				<div class="col-md-12">
					<div class="wizard-footer-top">
						<button class="btn btn-primary" @click="addToMyCar()">
							<font-awesome-icon icon="check"/>
							{{ $t('cars.addCar') }}
						</button>
					</div>
				</div>
			</div>
		</tab-content>


		<tab-content title="Last step" icon="ti-check">
			<CustomCarFormView
				@saveCar="saveVehicle"
				:modification="false"
				:msgs="msgs"
				:title="$t('cars.newVehicle')"
			/>
		</tab-content>

		<template slot="footer" slot-scope="props">

			<div class=wizard-footer-left>
				<div v-if="props.activeTabIndex > 0">
					<wizard-button @click.native="props.prevTab()">
						<font-awesome-icon icon="chevron-left"/>
						{{ $t('cars.backtocarselection') }}
					</wizard-button>
				</div>
			</div>

			<div class="wizard-footer-right">
				<div v-if="!props.isLastStep">
					<div class="reg-new-car-txt">{{ $t('cars.carNotFoundQuestion') }}</div>
					<wizard-button @click.native="props.nextTab()" class="" :style="props.fillButtonStyle">
						<font-awesome-icon icon="plus"/>
						{{ $t('cars.newVehicle') }}
					</wizard-button>
				</div>
				<div v-else>

				</div>
			</div>
		</template>
	</form-wizard>

</template>


<script>

import Vue from 'vue'
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import AutoComplete from "primevue/autocomplete/AutoComplete"
import CustomCarFormView from "@/components/CustomCarFormView"

Vue.use(VueFormWizard)

export default {
	name: 'CarRegistrationForm',
	components: {AutoComplete, CustomCarFormView},
	computed: {
		cancelBtnTitle() {
			return this.$t('cars.cancelButton')
		},
	},
	data() {
		return {
			vehicles: [],
			selectedVehicle: null,
			filteredVehicles: null,
			licencePlate: '',
			vehicle: {
				licencePlate: '',
				producer: '',
				model: '',
				yearOfProduction: '',
				batteryCapacityKWh: '',
				rangeKm: '',
				chargePorts: [],
				enginePowerKw: '',
				efficiencyKWhpKm: '',
				maxChargingPowerACKw: '',
				maxChargingPowerDCKw: '',
			},
			options: {
				multi: true,
				btnLabel: this.$t('custom-car-form.charger-type')
			},
			btnLabel: v => ""
		}
	},
	props: {
		msgs: Object
	},
	methods: {
		searchVehicles(event) {
			setTimeout(() => {
				if(!event.query.trim().length) {
					this.filteredVehicles = [...this.vehicles]
				}
				else {
					this.filteredVehicles = this.vehicles.filter((vehicle) => {
						if(event.query.includes(' ')) {
							const filterTextArray = event.query.split(' ')
							const firstFilterText = filterTextArray[0]
							const secondFilterText = filterTextArray[1]
							return vehicle.name.toLowerCase().includes(firstFilterText.toLowerCase()) && vehicle.name.toLowerCase().includes(secondFilterText.toLowerCase())
						}
						else {
							return vehicle.name.toLowerCase().includes(event.query.toLowerCase())
						}
					})
				}
			}, 250)
		},
		addToMyCar() {
			this.$rest.addCar(this.selectedVehicle ? this.selectedVehicle.id : null, this.licencePlate, (car, url) => {
				this.selectedVehicle = null
				this.licencePlate = null
				this.clearForms()
				car.image = url + car.image
				this.$store.commit('setCarAdditionSkipped', false)
				this.$store.commit('setUserHasCar', true)
				this.$emit('addCar', car)
			})
		},
		saveVehicle(vehicleToAdd) {
			this.$rest.addNewVehicle(vehicleToAdd, car => {
				this.clearForms()
				this.$store.commit('setCarAdditionSkipped', false)
				this.$store.commit('setUserHasCar', true)
				this.$emit('saveCar', car)
			})
		},
		changeTab() {
			this.$emit('changeTab', "2")
		},
		loadData() {
			this.$rest.loadVehicles(vehicles => {
				this.vehicles = vehicles
				this.$forceUpdate()
			})
		},
		clearForms() {
			this.selectedVehicle = null
			this.licencePlate = null
			this.vehicle.licencePlate = null
			this.vehicle.licencePlate = null
			this.vehicle.producer = null
			this.vehicle.model = null
			this.vehicle.yearOfProduction = null
			this.vehicle.batteryCapacityKWh = null
			this.vehicle.rangeKm = null
			this.vehicle.chargePorts = null
			this.vehicle.enginePowerKw = null
			this.vehicle.efficiencyKWhpKm = null
			this.vehicle.maxChargingPowerACKw = null
			this.vehicle.maxChargingPowerDCKw = null
		}
	},
	beforeMount() {
		this.loadData()
	},
	emits: ['saveCar', 'addCar', 'changeTab']
}

</script>

<style>
.charge-port-select {
  width: 100%;
}

.cp-text {
  margin-top: 1rem;
  font-size: 16px;
}

.vehicle-row {
  padding-top: 10px;
}

.btn-select {
  border: none !important;
  width: 100%;
  min-height: auto !important;
  align-items: flex-end !important;

}

.buttonLabel {
  float: right;
}

.hidden-wizard-container .wizard-navigation .wizard-progress-with-circle,
.hidden-wizard-container .vue-form-wizard .wizard-header,
.hidden-wizard-container .vue-form-wizard .wizard-nav-pills,
.hidden-wizard-container .wizard-progress-bar {
  display: none;
}

.wizard-footer-top {
  text-align: center;
}

.hidden-wizard-container .wizard-tab-container .alert.alert-warning {
  margin-top: 15px;
  margin-bottom: 5px;
}

.hidden-wizard-container .wizard-footer-right {
  text-align: right;
}

.hidden-wizard-container .wizard-footer-right .reg-new-car-txt {
  color: #343a40;
  font-weight: 500;
}

.hidden-wizard-container .wizard-footer-right button {
  width: 100%;
  margin-top: 10px;
}

.hidden-wizard-container .wizard-btn {
  background-color: rgb(231, 76, 60);
  border-color: rgb(231, 76, 60);
  color: white;
}

.hidden-wizard-container #vehicles > .vs__dropdown-toggle {
  height: calc(1.5em + .75rem + 2px);
}

.hidden-wizard-container .wizard-tab-content {
  -moz-box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.16);
  padding: 20px;
  margin: 20px 0px;
  position: relative;
}

.hidden-wizard-container .vue-form-wizard .wizard-card-footer {
  padding: 0px;
}

.hidden-wizard-container .fake-modal-cancel-button {
  position: absolute;
  right: 0px;
  top: -12px;
  font-size: 40px;
  color: #e74c3c;
  cursor: pointer;
}
</style>
