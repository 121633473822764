<template>
	<div class="simple-flex flex-grow-1" :class="{ 'hide-map': $route.name != 'index' && $route.name != 'map' && $route.name != 'map-lat-long' && $route.name != 'map-lat-long-route' }">
		<div class="button-div">
			<button class="mr-2" :class="['btn', showChargerClass]" @click="toggleShowChargers()" style="width: 45px">
				<font-awesome-icon icon="bolt" style="margin-right: -2px" />
			</button>
			<button class="mr-2" :class="['btn', showPoisClass]" @click="toggleShowPois()" style="width: 45px">
				<font-awesome-icon icon="shopping-cart" style="margin-right: 2px" />
			</button>
			<button :class="['btn', showGatesClass]" @click="toggleShowGates()" style="width: 45px">
				<font-awesome-icon icon="parking" size="lg" style="margin-right: 2px" />
			</button>
		</div>

		<div id="map" class="flex-grow-1 map"></div>

		<button class="myPositionButton" @click="moveMapToMarker()" v-if="showCenterOnGpsButton">
			<SvgIcon type="mdi" :path="gpsIcon" />
		</button>

		<div class="noGpsWarning" v-else>A helymeghatározás nem érhető el!</div>
	</div>
</template>

<script>
import gmapsInit from '@/plugins/gmaps';
import jQuery from 'jquery';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiCrosshairsGps } from '@mdi/js';

export default {
	name: 'GoogleMap',

	data() {
		return {
			app: null,
			locations: null,
			map: null,
			markers: null,
			markersByLocationId: {},
			chargingMarkers: [],
			google: null,
			markerCluster: null,
			myMarker: null,
			mapData: {
				locations: [],
				latitude: null,
				longitude: null,
				findroute: false
			},
			showChargers: true,
			showPois: true,
			showGates: true,
			locationDataWithPois: null,
			locationDataWithChargers: null,
			locationParkingLots: null,
			canReset: false,
			canMove: true,
			mapPromise: new Promise((resolve) => {
				this.mapPromiseResolve = resolve;
			}),
			directionsRenderer: null,
			chargingIcon: null,
			locationSet: false,
			gpsIcon: mdiCrosshairsGps
		};
	},

	props: {
		mapDataToLoad: Object
	},

	components: {
		SvgIcon
	},

	computed: {
		showCenterOnGpsButton() {
			return this.myMarker && this.myMarker.getPosition().lat() != 0 && this.myMarker.getPosition().lng() != 0;
		},
		showChargerClass() {
			if (this.showChargers) {
				return 'btn-success';
			} else {
				return 'btn-secondary';
			}
		},
		showPoisClass() {
			if (this.showPois) {
				return 'btn-success';
			} else {
				return 'btn-secondary';
			}
		},
		showGatesClass() {
			if (this.showGates) {
				return 'btn-success';
			} else {
				return 'btn-secondary';
			}
		},
		charge() {
			return this.$store.getters.getCharge;
		}
	},
	methods: {
		updateMyLocation(lat, lng) {
			if (!this.map) {
				return;
			}
			if (!this.myMarker) {
				this.myMarker = new this.google.maps.Marker({
					position: { lat: lat, lng: lng },
					title: location.name,
					map: this.map,
					clickable: false,
					cursor: 'crosshair',
					icon: {
						url: require('@/assets/marker_mylocation.png'),
						size: new this.google.maps.Size(100, 100),
						scaledSize: new this.google.maps.Size(20, 20),
						anchor: new this.google.maps.Point(10, 10)
					}
				});
			} else {
				this.myMarker.setPosition({ lat: lat, lng: lng });
			}

			if (!this.locationSet) {
				this.locationSet = true;
				this.moveMapToMarker();
			}
		},

		moveMapToMarker() {
			this.map.setZoom(15);
			this.offsetCenter(this.myMarker.getPosition(), false, false);
		},

		toggleShowChargers() {
			this.showChargers = !this.showChargers;
			localStorage.setItem('showChargers', this.showChargers ? '1' : '0');
			this.filterLocations();
		},

		toggleShowPois() {
			this.showPois = !this.showPois;
			localStorage.setItem('showPois', this.showPois ? '1' : '0');
			this.filterLocations();
		},

		toggleShowGates() {
			this.showGates = !this.showGates;
			localStorage.setItem('showGates', this.showGates ? '1' : '0');
			this.filterLocations();
		},

		getLocationsWithChargersAndPois() {
			this.showLocations(this.mapData);
		},

		filterLocations() {
			const mapDataToUpdate = { ...this.mapData };
			let arr = mapDataToUpdate.locations;
			if (typeof arr === 'object') {
				arr = Object.values(arr);
			}
			const filteredLocations = arr.filter((l) => {
				return (this.showChargers && l.hasCharger) || (this.showPois && l.hasPoi) || (this.showGates && l.hasParking);
			});
			mapDataToUpdate.locations = [...filteredLocations];
			this.showLocations(mapDataToUpdate);
		},

		async initMap() {
			if (!this.google) {
				this.google = await gmapsInit();
			}

			if (!this.map) {
				this.map = new this.google.maps.Map(jQuery('#map')[0], {
					zoom: 12,
					center: { lat: 47.498362, lng: 19.04046 },
					mapTypeId: 'terrain',
					gestureHandling: 'greedy',
					fullscreenControl: false
				});

				const noPoi = [
					{
						featureType: 'poi',
						elementType: 'labels',

						stylers: [
							{
								visibility: 'off'
							}
						]
					}
				];

				this.map.setOptions({
					styles: noPoi
				});

				window.addEventListener('load', () => {
					this.mapPromiseResolve();
				});
				await this.mapPromise;
			} else {
				if (this.markerCluster) {
					this.markerCluster.setMap(null);
					this.markerCluster = null;
				}
			}
		},

		resetMap() {
			if (!this.canReset) {
				return;
			}
			if (this.markerCluster) {
				this.markerCluster.clearMarkers();
				this.markerCluster.setMap(null);
				this.markerCluster = null;
			}
			if (this.directionsRenderer) {
				this.directionsRenderer.setMap(null);
			}
			this.markers = [];
			this.markersByLocationId = {};
			this.canReset = false;
		},

		async showLocations(newMapData) {
			await this.mapPromise;

			this.resetMap();
			this.canReset = true;

			this.markers = [];
			this.markersByLocationId = {};
			this.locations = newMapData.locations;

			const bounds = new this.google.maps.LatLngBounds();

			let charge = this.charge;

			this.chargingIcon = {
				url: require('@/assets/marker_car.svg'),
				size: new this.google.maps.Size(64, 97),
				scaledSize: new this.google.maps.Size(40, 61),
				anchor: new this.google.maps.Point(20, 61)
			};

			if (this.locations && this.locations.length > 0) {
				for (let i = 0; i < this.locations.length; i++) {
					const location = this.locations[i];

					let url = null;
					let icon = 0;
					if (this.showChargers && location.hasCharger) icon += 1;
					if (this.showPois && location.hasPoi) icon += 2;
					if (this.showGates && location.hasParking) icon += 4;
					if (icon === 0) continue;

					switch (icon) {
						case 1:
							url = require('@/assets/marker_charger.svg'); // charger
							break;
						case 2:
							url = require('@/assets/marker_service.svg'); // poi
							break;
						case 3:
							url = require('@/assets/marker_both.svg'); // charger + poi
							break;
						case 4:
							url = require('@/assets/marker_parking.svg'); // parking
							break;
						case 5:
							url = require('@/assets/marker_charger_parking.svg'); // charger + parking
							break;
						case 6:
							url = require('@/assets/marker_service_parking.svg'); // poi + parking
							break;
						case 7:
							url = require('@/assets/marker_both_parking.svg'); // charger + poi + parking
							break;
					}
					const marker = new this.google.maps.Marker({
						position: { lat: location.latitude, lng: location.longitude },
						title: location.name,
						icon: {
							url: url,
							size: new this.google.maps.Size(64, 97),
							scaledSize: new this.google.maps.Size(40, 61),
							anchor: new this.google.maps.Point(20, 61)
						}
					});
					marker.originalIcon = marker.icon;
					if (charge && charge.locationId && charge.locationId == location.id) {
						marker.setIcon(this.chargingIcon);
						this.chargingMarkers.push(marker);
						marker.charge = charge;
					}
					this.markers.push(marker);
					this.markersByLocationId[location.id] = marker;
					bounds.extend(marker.position);
					marker.location = location;
					const self = this;
					marker.addListener('click', function () {
						if (self.charge) {
							if (self.$route.name != 'charge-history') {
								self.$router.push('/charge-history');
							}
							return;
						}
						self.map.setZoom(19);
						self.offsetCenter(this.getPosition(), true, false);
						self.downloadAndShowLocationDetails(this.location.id);
					});
				}
			}

			this.markerCluster = new MarkerClusterer(this.map, this.markers, {
				styles: [
					{
						url: require('@/assets/cluster.svg'),
						width: 50,
						height: 50,
						anchorIcon: [25, 25],
						anchorText: [15, 0],
						backgroundPosition: '0, 0',
						textColor: '#ffffff',
						textSize: 16
					}
				],
				maxZoom: 15,
				minimumClusterSize: 2
			});

			if (this.canMove) {
				this.canMove = false;
				if (newMapData.latitude && newMapData.longitude && !newMapData.findroute) {
					this.offsetCenter(new this.google.maps.LatLng(newMapData.latitude, newMapData.longitude), false, true);
					this.map.setZoom(17);
				} else if (!newMapData.findroute) {
					this.app.getLocation(
						(pos) => {
							this.offsetCenter(new this.google.maps.LatLng(pos.coords.latitude, pos.coords.longitude), false, true);
							this.map.setZoom(15);
						},
						(err) => {
							if (bounds.Hh.lo != 180 || bounds.Hh.hi != -180 || bounds.Yh.lo != 1 || bounds.Yh.hi != -1) {
								this.map.fitBounds(bounds);
							}
						}
					);
				} else {
					this.calculateRoute(newMapData);
				}
			} else {
				this.google.maps.event.trigger(this.map, 'resize');
			}
		},

		async downloadAndShowLocationDetails(id, tab) {
			await Promise.all([this.getLocationParkingLots(id), this.getLocationsWithPois(id), this.getLocationsWithChargers(id)]);
			this.$emit('showLocationPanel', this.locationDataWithPois, this.locationDataWithChargers, this.locationParkingLots, tab);
		},

		moveToCurrentMarker() {
			if (this.locationDataWithChargers && this.locationDataWithChargers.latitude && this.locationDataWithChargers.longitude) {
				this.map.setZoom(19);
				this.offsetCenter({ lat: this.locationDataWithChargers.latitude, lng: this.locationDataWithChargers.longitude }, true, false);
			}
		},

		async getLocationsWithPois(id) {
			await this.$rest.getLocationWithPoi(id, (locationWithPois, url) => {
				locationWithPois.pois.forEach((p) => {
					if (p.pictureUrl != null) {
						p.pictureUrl = url + p.pictureUrl;
					}
				});
				this.locationDataWithPois = locationWithPois;
			});
		},

		async getLocationParkingLots(id) {
			this.locationParkingLots = await this.$rest.getLocationParkingLots(id);
		},

		async getLocationsWithChargers(id) {
			return this.$rest.getLocation(id, false, (locationsWithChargers) => {
				this.locationDataWithChargers = locationsWithChargers;
			});
		},

		offsetCenter(latlng, alignLeft, duringInit) {
			if (!latlng || typeof latlng.lat !== 'function' || typeof latlng.lng !== 'function') {
				return;
			}

			const lat = latlng.lat();
			const lng = latlng.lng();

			if (lat == 0 && lng == 0) {
				return;
			}

			let mapWidth = jQuery('#map').width();
			let buttonsHeight = jQuery('#mainButtons').height();
			if (!buttonsHeight) {
				buttonsHeight = 0;
			}

			let offsetx = alignLeft && mapWidth > 360 ? mapWidth / -4 : 0;
			let offsety = buttonsHeight / (duringInit ? -16 : -2);

			var scale = Math.pow(2, this.map.getZoom());

			var worldCoordinateCenter = this.map.getProjection().fromLatLngToPoint(latlng);
			var pixelOffset = new this.google.maps.Point(offsetx / scale || 0, offsety / scale || 0);

			var worldCoordinateNewCenter = new this.google.maps.Point(worldCoordinateCenter.x - pixelOffset.x, worldCoordinateCenter.y + pixelOffset.y);

			var newCenter = this.map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

			this.map.setCenter(newCenter);
		},

		calculateRoute(newMapData) {
			if (!newMapData.findroute) {
				return;
			}
			let hasRouteApiAccess = true;
			const lat = parseFloat(newMapData.latitude);
			const long = parseFloat(newMapData.longitude);
			if (hasRouteApiAccess) {
				this.app.getLocation(
					(position) => {
						const actualLatitude = position.coords.latitude;
						const actualLongitude = position.coords.longitude;

						const from = new this.google.maps.LatLng(lat, long);
						const to = new this.google.maps.LatLng(actualLatitude, actualLongitude);
						const mode = this.google.maps.TravelMode.DRIVING;

						const directionsService = new this.google.maps.DirectionsService();
						this.directionsRenderer = new this.google.maps.DirectionsRenderer();
						this.directionsRenderer.setMap(this.map);

						directionsService.route(
							{
								origin: from,
								destination: to,
								travelMode: mode
							},
							(response, status) => {
								if (status === 'OK') {
									this.directionsRenderer.setDirections(response);
								} else {
									//console.log("cannot calculate route")
								}
							}
						);
					},
					(thereIsGeoLocationSupport) => {
						// No GPS
					}
				);
			}
		},

		showLocationPanel(locationId, tab) {
			let marker = this.markersByLocationId[locationId];
			if (marker) {
				this.map.setZoom(19);
				this.offsetCenter(marker.getPosition(), true, false);
			}
			this.downloadAndShowLocationDetails(locationId, tab);
		},

		reloadFilterButtonsStates() {
			this.showChargers = !(localStorage.getItem('showChargers') === '0');
			this.showPois = !(localStorage.getItem('showPois') === '0');
			this.showGates = localStorage.getItem('showGates') === '1';
			this.filterLocations();
		}
	},

	emits: ['showLocationPanel'],

	watch: {
		mapDataToLoad(newMapData) {
			this.mapData = newMapData;
			this.showLocations(this.mapData);
		},
		charge(newCharge) {
			let marker = null;
			if (newCharge && newCharge.locationId) {
				marker = this.markersByLocationId[newCharge.locationId];
				if (marker) {
					marker.setIcon(this.chargingIcon);
					marker.charge = newCharge;
				}
			}

			let found = false;

			for (let i = this.chargingMarkers.length - 1; i >= 0; i--) {
				let m = this.chargingMarkers[i];
				if (newCharge && newCharge.locationId && m.location.id === newCharge.locationId) {
					found = true;
				} else {
					m.setIcon(m.originalIcon);
					m.charge = null;
					this.chargingMarkers.splice(i, 1);
				}
			}

			if (marker && !found) {
				this.chargingMarkers.push(marker);
			}
		}
	},

	created() {
		this.app = this.$root.$children[0];
	},

	async mounted() {
		this.reloadFilterButtonsStates();
		await this.initMap();
	}
};
</script>

<style scoped>
.simple-flex {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-direction: column;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
}

.button-div {
	z-index: 1000;
	position: absolute;
	width: 152px;
	left: 10px;
	bottom: 30px;
}

.map {
	height: 100%;
	min-height: 500px;
}
</style>
