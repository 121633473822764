<template>
	<div>
		<div style="position: relative;">

			<div class="font-weight-bold">
				{{ parkingLot.name }}
			</div>

			<div class="media">
				<div class="media-body align-self-center">
					<template v-if="parkingLot.tariff">
						<div v-if="parkingLot.tariff.freeParking" class="text-success">{{ $t('location-panel.free') }}</div>
						<div v-else class="text-danger">{{ $t('location-panel.price-parking', [parkingLot.tariff.grossUnitParking]) }}</div>
					</template>

				</div>

			</div>

			<div v-if="canStart">


				<template>
					<button @click="$emit('showStartParking', parkingLot.id)" class="btn btn-success stretched-link w-100 mt-1">
						<font-awesome-icon icon="play" class="fa-lg mr-2" style="vertical-align: middle; margin-bottom: 2px;"/>
						{{ $t('parkingView.startParking') }}
					</button>
				</template>

			</div>

		</div>
	</div>
</template>

<script>
import jQuery from 'jquery'
import TraitValue from '@/components/TraitValue.vue'
import { GlobalEventEmitter } from '@/plugins/GlobalEventEmitter'

export default {
	name: 'LocationPanelParking',
	props: { parkingLot: Object },
	components: { TraitValue },
	data() {
		return {
			performance: '',
			currentType: '',
			outlets: [],
			connectorState: '',
			connectorStateKey: '',
			thereIsRunningSession: true,
			traitDetails: [],
			showInfo: false,
			showPanel: true
		}
	},
	methods: {

	},
	mounted() {

	},
	computed: {

		canStart() {
			return true
		}
	},
	emits: ['hideLocationPanel', 'showStartParking']
}
</script>

<style>
.location-panel-charger-name {
  font-size: 26px;
  font-weight: bold;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #333333;
}

.connector-state {
  font-size: 18px;
  font-weight: bold;
}

.connector-state-available {
  color: var(--mode-color);
}

.connector-state-unavailable {
  color: #b21914;
}

.connector-state-other {
  color: #AAAAAA;
}

.connector-state-preparing {
  color: var(--mode-color);
}

.outlet-item {
  white-space: nowrap;
}

.outlet-item img {
  vertical-align: middle;
  margin-right: 4px;
}

.outlet-item span {
  font-size: 0.9rem;
  vertical-align: middle;
}

.location-panel-charger-button {
  font-size: 16px;
  font-weight: bold;
  padding: 15px 30px;
  margin: 0 auto;
}

.charger-description p {
  margin-bottom: 0;
}

.charger-info-toggle-button {
  width: 100%;
  border: none;
  background: transparent;
  color: #999999;
  transform: translateY(8px);
}

</style>
