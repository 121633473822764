<template>
	<div class="loginCard" v-if="showLogin">
		<form>

			<div class="row align-items-center">
				<div class="col-sm-4 form-group">
					<label for="email">{{ $t('login.email') }}:</label>
				</div>
				<div class="col-sm-8 form-group">
					<input type="text" v-model.trim="email" class="form-control" autocorrect="off" autocapitalize="none" />
				</div>
			</div>

			<div class="row align-items-center">
				<div class="col-sm-4 form-group">
					<label for="password">{{ $t('login.password') }}:</label>
				</div>
				<div class="col-sm-8 form-group">
					<input type="password" v-model.trim="password" class="form-control" />
				</div>
			</div>

			<div class="d-flex flex-column align-items-center">
				<div class="text-center login--btn">
					<button
						@click="login"
						class="btn btn-primary"
						type="button"
						v-on:keyup.enter="login"
						style="min-width: 280px; height: 44px;"
					>
						<font-awesome-icon icon="sign-in-alt" size="lg" style="float: left; margin-top: 2px;" />
						{{ $t('login.loginButton') }}
					</button>
				</div>
			</div>

		</form>

		<div class="my-4 mx-auto d-flex flex-column align-items-center" style="border: 1px solid #ddd; padding: 10px 5px 20px 5px; max-width: 320px;">
			<div style="margin-bottom: 10px;">
				{{ $t('login.socialLoginHeader') }}
			</div>
			<div v-if="showAppleNativeLoginButton">
				<button class="loginBtn loginBtn--apple mb-3" @click="appleLogin">
					{{ $t('login.appleLoginButton') }}
				</button>
			</div>

			<div style="margin-bottom: 12px;">
				<div v-if="!nativeGoogle">
					<div
						id="g_id_onload"
						data-client_id="1089962333512-761ijatbd025mtd48dtcec70g0mjauqr.apps.googleusercontent.com"
						data-context="signin"
						data-ux_mode="popup"
						data-callback="googleLogin"
						data-auto_select="false"
						data-itp_support="true"
						:data-locale="$i18n.locale"
					>
					</div>

					<div
						class="g_id_signin"
						data-type="standard"
						data-shape="rectangular"
						data-theme="outline"
						data-text="continue_with"
						data-size="large"
						data-logo_alignment="left"
						data-width="280"
						:data-locale="$i18n.locale"
					>
					</div>
				</div>

				<button class="loginBtn loginBtn--google m-0" @click="googleNativeLogin" v-else>
					{{ $t('login.googleLoginButton') }}
				</button>
			</div>

			<div
				v-if="!showAppleNativeLoginButton"
				style="height: 44px; background-color: black; padding-top: 4px; padding-left: 4px; border-radius: 4px;"
				id="appleid-signin"
				class="apple-signin"
				data-mode="left-align"
				data-type="sign-in"
				data-color="black"
				data-border="false"
				data-border-radius="8"
				data-width="276"
				data-height="36"
				data-logo-size="large"
				data-label-position="84"
			></div>

			<!--div>
        <button class="loginBtn loginBtn--facebook" @click="fbLogin">
          {{ $t('login.fbLoginButton') }}
        </button>
      </div-->
		</div>

		<i18n path="login-view.accept-message" tag="div">
			<template v-slot:privacy-statement>
				<a href="javascript:void(0)" @click="showPrivacyPolicy()" class="btn btn-sm btn-outline-secondary">{{ $t('aboutUs.privacyStatement') }}</a>
			</template>
			<template v-slot:gtc>
				<a href="javascript:void(0)" @click="showGTC()" class="btn btn-sm btn-outline-secondary">{{ $t('aboutUs.termsAndConditions') }}</a>
			</template>
		</i18n>

		<div class="text-right mb-3">
			<router-link to="register">{{ $t('login.registerLink') }}</router-link>
		</div>
		<div class="text-right">
			<router-link to="forgotten-password">{{ $t('login.forgottenPasswordLink') }}</router-link>
		</div>
	</div>
</template>

<script>
import GtcView from '@/views/GtcView.vue'
import GtcENView from '@/views/GtcENView.vue'
import PrivacyStatementView from '@/views/PrivacyStatementView.vue'
import PrivacyStatementENView from '@/views/PrivacyStatementENView.vue'

export default {
	name: 'LoginView',
	data() {
		return {
			app: null,
			signedIn: false,
			email: '',
			password: '',
			params: {
				client_id: "1089962333512-761ijatbd025mtd48dtcec70g0mjauqr.apps.googleusercontent.com"
			},
			nativeFacebook: 'ios' === window.platform || 'android' === window.platform,
			nativeGoogle: 'ios' === window.platform || 'android' === window.platform
		}
	},
	props: {
		loggedInUserData: Object
	},
	computed: {
		showLogin() {
			return this.loggedInUserData == null || !this.loggedInUserData.loggedInEmail
		},
		showAppleNativeLoginButton() {
			return 'ios' === window.platform || 'android' === window.platform
		}
	},
	methods: {
		showPrivacyPolicy() {
			let components = [
				{ component: this.$i18n.locale == 'hu' ? PrivacyStatementView : PrivacyStatementENView }
			]

			this.app.showBSModal(components, this.$t('aboutUs.privacyStatement'), null, true)
		},
		showGTC() {
			let components = [
				{ component: this.$i18n.locale == 'hu' ? GtcView : GtcENView }
			]

			this.app.showBSModal(components, this.$t('aboutUs.termsAndConditions'), null, true)
		},
		login() {
			this.$emit('login', this.email, this.password, (userData) => {
				this.email = null
				this.password = null
			})
		},
		googleNativeLogin() {
			window.loadAppLink('freecharger://google-login')
		},
		googleLoginFailure(p) {
			//console.log(p);
		},
		fbLogin() {
			if(this.nativeFacebook) {
				window.loadAppLink('freecharger://facebook-login')
			}
			else {
				window.FB.login((resp) => {
					const authResponse = resp.authResponse
					if(authResponse) {
						this.$emit('fbLogin', authResponse)
					}
				}, { scope: "email", return_scope: true })
			}
		},
		appleLogin() {
			window.loadAppLink('freecharger://apple-login')
		},
		createWebAppleLogin() {
			if(!window.AppleID) {
				console.log('"https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js" needs to be included as a <script>')
			}
			window.AppleID.auth.init({
				clientId: 'hu.r2g.freecharger.login',
				scope: 'name email',
				redirectURI: this.$rest.baseUrl + '/v1/user/apple-return',
				usePopup: true
			})
			document.addEventListener('AppleIDSignInOnSuccess', (event) => {
				const code = event.detail.authorization.code
				const user = event.detail.authorization.user
				this.$rest.loginWithApple(code, user, (response, extraData) => {
					this.$emit('appleWebLogin', response, extraData)
				})
			})
			document.addEventListener('AppleIDSignInOnFailure', (event) => {
				alert(this.$t('login.appleLoginFailed'))
			})
		}
	},
	created() {
		this.app = this.$root.$children[0]
	},
	mounted() {
		let gsiScript = document.createElement('script')
		gsiScript.setAttribute('src', 'https://accounts.google.com/gsi/client')
		document.head.appendChild(gsiScript)

		this.createWebAppleLogin()
		if('ios' !== window.platform) {
			const fbScript = document.createElement('script')
			fbScript.setAttribute('src', 'https://connect.facebook.net/hu_HU/sdk.js#xfbml=1&version=v8.0&appId=3604530536246633&autoLogAppEvents=1')
			document.head.appendChild(fbScript)
		}
	},
	components: {

	},
	emits: ['login', 'fbLogin', 'googleLogin']
}
</script>

<style>
.loginCard {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
}

button.login-button svg {
  height: 15px;
  margin-top: -3px;
  margin-right: 3px;
}
</style>
