<template>
	<div>
		<div>
			<div>
				<h2 class="header-common">
					{{ $t('coupon-view.points') }}
				</h2>
				<div class="actual-points text-center pt-0">
					<span class="actual-points-text">{{ actualPoints }}</span>
				</div>
			</div>

			<div class="form-group buttonRow text-center">
				<button @click="showMyCoupons()" class="btn my-2 mx-2" :class="myCoupons ? 'btn-primary' : 'btn-outline-secondary'">
					<font-awesome-icon icon="ticket-alt" />
					{{ $t('coupon-view.active-coupons') }}
				</button>
				<button @click="buyCoupon()" class="btn my-2 mx-2" :class="couponOffer ? 'btn-primary' : 'btn-outline-secondary'">
					<font-awesome-icon icon="shopping-basket" />
					{{ $t('coupon-view.coupons') }}
				</button>
				<button @click="showHistoryPanel()" class="btn my-2 mx-2" :class="showHistory ? 'btn-primary' : 'btn-outline-secondary'">
					<font-awesome-icon icon="history" />
					{{ $t('coupon-view.history') }}
				</button>
			</div>

			<div v-if="showHistory">
				<div class="row">
					<div class="col-md-4 d-flex flex-column" v-for="coupon in history" :key="coupon.couponCodeId">
						<div class="card coupon-card flex-grow-1" :class="coupon.styleClass ? coupon.styleClass : ''">
							<div class="coupon-bg"></div>
							<div class="card-body">
								<h2 class="text-center coupon-card-shop">{{ coupon.shopName }}</h2>
								<div class="card-text text-center coupon-web-link-container">
									<a class="coupon-card-url stretched-link" target="_blank" :href="coupon.shopUrl">{{ $t('coupon-view.check-website') }}</a>
								</div>
								<div v-if="coupon.type == 'WEBSHOP'">
									<h4 class="card-text text-center coupon-card-name">{{ coupon.name }}</h4>
									<h4 v-if="coupon.price" class="card-text text-center coupon-card-price">{{ coupon.discountPercent }}% {{ $t('coupon-view.discount') }}</h4>
									<h5 v-if="coupon.price" class="card-text text-center">{{ coupon.price }} {{ $t('coupon-view.point') }}</h5>
									<div class="card-text text-center coupon-card-condition">{{ coupon.couponCondition }}</div>
								</div>
								<div v-else>
									<h4 class="card-text text-center coupon-card-name">{{ coupon.name }}</h4>
									<h4 class="card-text text-center coupon-card-price">{{ coupon.price }} {{ $t('coupon-view.ft') }}</h4>
									<div class="card-text text-center coupon-card-condition">{{ coupon.couponCondition }}</div>
								</div>
							</div>

							<div class="card-footer coupon-card-info-footer text-center" v-if="coupon.description">
								<a class="coupon-card-buy-bottom stretched-link" target="_blank" :href="coupon.description">
									<font-awesome-icon icon="info-circle" size="lg" class="mr-2" />
									{{ $t('coupon-view.more-details') }}
								</a>
							</div>

							<div class="card-footer coupon-card-footer text-center">
								<h4 class="card-text text-center coupon-card-code">{{ $t('coupon-view.expired-coupons') }}</h4>
								<h4 class="card-text text-center coupon-card-expire" v-if="coupon.expire">{{ $t('coupon-view.expired-time') }}: {{ coupon.expire }}</h4>
								<h4 class="card-text text-center coupon-card-code">{{ $t('coupon-view.coupon-code') }}: {{ coupon.couponCode }}</h4>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="myCoupons">
				<div class="row">
					<div class="col-md-4 d-flex flex-column" v-for="coupon in filteredUserCoupons" :key="coupon.couponCodeId">
						<div class="card coupon-card flex-grow-1" :class="coupon.styleClass ? coupon.styleClass : ''">
							<div class="coupon-bg"></div>
							<div class="card-body">
								<h2 class="text-center coupon-card-shop">{{ coupon.shopName }}</h2>
								<div class="card-text text-center coupon-web-link-container">
									<a class="coupon-card-url stretched-link" target="_blank" :href="coupon.shopUrl">{{ $t('coupon-view.check-website') }}</a>
								</div>

								<div v-if="coupon.activationUrl" class="text-center">
									<a :href="coupon.activationUrl" target="_blank" class="btn btn-danger mt-3" style="white-space: normal">{{ coupon.activationUrlDescription }}</a>
									<div class="card-text text-center coupon-card-condition">{{ coupon.couponCondition }}</div>
								</div>

								<template v-else>
									<div v-if="coupon.type == 'WEBSHOP'">
										<h4 class="card-text text-center coupon-card-name">{{ coupon.name }}</h4>
										<h4 v-if="coupon.price" class="card-text text-center coupon-card-price">{{ coupon.discountPercent }}% {{ $t('coupon-view.discount') }}</h4>
										<h5 v-if="coupon.price" class="card-text text-center">{{ coupon.price }} {{ $t('coupon-view.point') }}</h5>
										<div class="card-text text-center coupon-card-condition">{{ coupon.couponCondition }}</div>
									</div>
									<div v-else>
										<h4 class="card-text text-center coupon-card-name">{{ coupon.name }}</h4>
										<h4 class="card-text text-center coupon-card-price">{{ coupon.price }} {{ $t('coupon-view.ft') }}</h4>
										<div class="card-text text-center coupon-card-condition">{{ coupon.couponCondition }}</div>
									</div>
								</template>
							</div>

							<div class="card-footer coupon-card-info-footer text-center" v-if="coupon.description">
								<a class="coupon-card-buy-bottom stretched-link" target="_blank" :href="coupon.description">
									<font-awesome-icon icon="info-circle" size="lg" class="mr-2" />
									{{ $t('coupon-view.more-details') }}
								</a>
							</div>

							<div class="card-footer coupon-card-footer text-center">
								<h4 class="card-text text-center coupon-card-code">{{ $t('coupon-view.coupon-code') }}: {{ coupon.couponCode }}</h4>
								<h4 class="card-text text-center coupon-card-text" v-if="coupon.expire">
									<i>{{ $t('coupon-view.time-left') }}</i>
								</h4>
								<h4 class="card-text text-center coupon-card-expire" v-if="coupon.expire">{{ coupon.expireSec }}</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="couponOffer">
			<!-- <div class="row">
				<div class="col-12 text-center important-head">{{ $t('coupon-view.important-info') }}</div>
				<div class="col-12 text-center important-txt">
					<span v-html="$t('coupon-view.important-message')"> </span>
				</div>
			</div> -->

			<div class="row text-center">
				<div class="col-12 form-group">
					<label for="filterCriteria">{{ $t('coupon.settlement') }}</label>
					<div class="input-group">
						<input id="filterCriteria" type="text" @input="filterData" class="form-control" />
					</div>
				</div>
			</div>
			<div class="row my-4">
				<div v-for="trait in categories" :key="trait.key" class="text-center">
					<span @click="filterByTrait(trait.key)" class="filter-category" :class="selectedCategory === trait.key ? 'selected-trait' : ''">{{ trait.text }}</span>
				</div>
				<div class="text-center">
					<span @click="removeTraitFilter" class="filter-category">{{ $t('coupon-view.every') }}</span>
				</div>
			</div>

			<div class="row">
				<div class="col-md-4 d-flex flex-column" v-for="coupon in filteredCouponInventory" :key="coupon.id">
					<div class="card coupon-card flex-grow-1" :class="coupon.styleClass ? coupon.styleClass : coupon.price > actualPoints ? 'disabled-coupon' : ''">
						<div class="coupon-bg"></div>
						<div class="card-body">
							<h3 class="text-center coupon-card-shop">{{ coupon.shopName }}</h3>
							<div class="card-text text-center coupon-web-link-container">
								<a class="coupon-card-url stretched-link" target="_blank" :href="coupon.shopUrl">{{ $t('coupon-view.check-website') }}</a>
							</div>

							<div v-if="coupon.type == 'WEBSHOP'">
								<h4 class="card-text text-center coupon-card-name">{{ coupon.name }}</h4>
								<h4 v-if="coupon.price" class="card-text text-center coupon-card-price">{{ coupon.discountPercent }}% {{ $t('coupon-view.discount') }}</h4>
								<h5 v-if="coupon.price" class="card-text text-center">{{ coupon.price }} {{ $t('coupon-view.point') }}</h5>
								<div class="card-text text-center coupon-card-condition">{{ coupon.couponCondition }}</div>
							</div>
							<div v-else>
								<h4 class="card-text text-center coupon-card-name">{{ coupon.name }}</h4>
								<h4 v-if="coupon.price" class="card-text text-center coupon-card-price">{{ coupon.price }} {{ $t('coupon-view.ft') }}</h4>
								<div class="card-text text-center coupon-card-condition">{{ coupon.couponCondition }}</div>
							</div>
						</div>

						<div class="card-footer coupon-card-info-footer text-center" v-if="coupon.description">
							<a class="coupon-card-buy-bottom stretched-link" target="_blank" :href="coupon.description">
								<font-awesome-icon icon="info-circle" size="lg" class="mr-2" />
								{{ $t('coupon-view.how-to-use') }}
							</a>
						</div>

						<div class="card-footer coupon-card-buy-footer text-center" v-if="coupon.styleClass === 'onlinepenztarca'">
							<div class="coupon-card-buy-bottom" @click="buyCouponPressed(coupon)">
								<font-awesome-icon icon="wallet" size="lg" class="mr-2" />
								{{ $t('coupon-view.online-wallet-deposit') }}
							</div>
						</div>

						<a :href="coupon.activationUrl" target="_blank" class="card-footer coupon-card-buy-footer text-center" v-else-if="coupon.activationUrl">
							<span class="coupon-card-buy-bottom">
								{{ coupon.activationUrlDescription }}
							</span>
						</a>

						<div class="card-footer coupon-card-buy-footer text-center" v-else-if="coupon.price <= actualPoints">
							<div class="coupon-card-buy-bottom" @click="buyCouponPressed(coupon)">
								<font-awesome-icon icon="shopping-cart" size="lg" class="mr-2" />
								{{ $t('coupon-view.activate-coupons') }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<modal @closed="onModalClose()" name="onlinewalletmodal" :adaptive="true" height="auto">
				<div class="mx-3 mx-sm-5 my-5 text-center">
					<div v-if="onlineWalletDepositResult !== null">
						<div v-if="onlineWalletDepositResult === true">
							<h3 class="mb-3 text-success font-weight-bold">
								{{ $t('coupon-view.deposit-successful') }}
							</h3>
							<p v-html="$t('coupon-view.deposit-successful-desc', { href: 'https://www.onlinepenztarca.hu/login/rememberpassword' })"></p>
						</div>
						<div v-if="onlineWalletDepositResult === false">
							<h3 class="mb-3 text-danger font-weight-bold">
								{{ $t('coupon-view.deposit-failed') }}
							</h3>
							<p v-html="$t('coupon-view.deposit-failed-desc')"></p>
						</div>
						<div class="d-flex justify-content-center">
							<button @click="onModalClose()" class="btn btn-secondary px-4 py-2 mx-2 mt-3">{{ $t('coupon-view.close') }}</button>
							<a href="https://www.onlinepenztarca.hu/onlinepenztarcam" class="btn btn-primary px-3 py-2 mx-2 mt-3" target="_blank">
								{{ $t('coupon-view.go-to-profile') }}
								<font-awesome-icon icon="external-link-alt" />
							</a>
						</div>
					</div>
					<div v-else>
						<h3 class="mb-3">
							{{ $t('coupon-view.online-wallet-deposit') }}
						</h3>
						<div class="text-left" v-if="onlineWalletUserCreationMode">
							<p class="text-center" v-html="$t('coupon-view.online-wallet-account-creation-desc', { href: 'http://onlinepenztarca.hu' })"></p>

							<small class="d-flex justify-content-center align-items-center mt-3">
								<label class="d-inline-block" for="onlineWalletDataPolicyAccepted" v-html="$t('coupon-view.online-wallet-data-policy', { href: 'https://www.onlinepenztarca.hu/tartalmak/adatvedelem' })"></label>
								<input id="onlineWalletDataPolicyAccepted" type="checkbox" v-model="onlineWalletDataPolicyAccepted" class="m-0 mx-2" />
							</small>
							<small class="d-flex justify-content-center align-items-center mt-3">
								<label class="d-inline-block" for="onlineWalletTermsAccepted" v-html="$t('coupon-view.online-wallet-gtc', { href: 'https://www.onlinepenztarca.hu/tartalmak/aszf' })"></label>
								<input id="onlineWalletTermsAccepted" type="checkbox" v-model="onlineWalletTermsAccepted" class="m-0 mx-2" />
							</small>

							<div class="d-flex justify-content-center">
								<button @click="onModalClose()" class="btn btn-outline-secondary mr-2 px-4 py-2 mt-3">{{ $t('coupon-view.close') }}</button>
								<button @click="onlineWalletDeposit()" class="btn btn-success px-4 py-2 mt-3">{{ $t('coupon-view.create-account-and-deposit') }}</button>
							</div>
						</div>
						<div v-else>
							<div>
								<label>{{ $t('coupon-view.points-to-deposit') }}: </label>
								<div class="d-flex justify-content-center flex-wrap" style="gap: 1rem">
									<button :class="`btn deposit-amount ${onlineWalletDepositAmount === 500 ? 'active-amount' : ''}`" @click="setDepositAmount(500)" :disabled="actualPoints < 500">500</button>
									<button :class="`btn deposit-amount ${onlineWalletDepositAmount === 1000 ? 'active-amount' : ''}`" @click="setDepositAmount(1000)" :disabled="actualPoints < 1000">1000</button>
									<button :class="`btn deposit-amount ${onlineWalletDepositAmount === 3000 ? 'active-amount' : ''}`" @click="setDepositAmount(3000)" :disabled="actualPoints < 3000">3000</button>
									<button :class="`btn deposit-amount ${onlineWalletDepositAmount === 5000 ? 'active-amount' : ''}`" @click="setDepositAmount(5000)" :disabled="actualPoints < 5000">5000</button>
									<button :class="`btn deposit-amount ${onlineWalletDepositAmount === 10000 ? 'active-amount' : ''}`" @click="setDepositAmount(10000)" :disabled="actualPoints < 10000">10000</button>
									<button :class="`btn deposit-amount ${onlineWalletDepositAmount === 15000 ? 'active-amount' : ''}`" @click="setDepositAmount(15000)" :disabled="actualPoints < 15000">15000</button>
								</div>
							</div>
							<div class="mt-3">
								<label>{{ $t('coupon-view.email') }}: </label>
								<TextInput class="form-control" v-model="onlineWalletEmail" :msgs="msgs" type="email" />
							</div>
							<div class="d-flex justify-content-center">
								<button @click="onModalClose()" class="btn btn-outline-secondary mr-2 px-4 py-2 mt-3">{{ $t('coupon-view.close') }}</button>
								<button @click="onlineWalletDeposit()" class="btn btn-success px-4 py-2 mt-3">{{ $t('coupon-view.deposit') }}</button>
							</div>
						</div>
					</div>
				</div>
			</modal>
		</div>
	</div>
</template>

<style>
.btn.deposit-amount {
	border: solid 2px #3370a3;
	color: #3370a3;
	transition: 0.1s all !important;
}

.btn.deposit-amount:hover {
	color: #3370a3;
}

.btn.deposit-amount.active-amount {
	background: #3370a3;
	color: white;
}

.btn.deposit-amount.disabled {
	background: #3370a3;
	color: white;
}

.filter-category {
	font-weight: bold;
	padding: 10px 10px;
}

.filter-category:hover {
	color: blue;
}

.selected-trait {
	background: var(--mode-color);
	color: #ffffff;
	border-radius: 5px;
}

.coupon-card-footer {
	background: var(--mode-color) !important;
}

.coupon-card .card-body {
	border-bottom: 1px solid black;
}

.coupon-card-info-footer {
	border-bottom: 1px solid black;
	background: #509cd5 !important;
	position: relative;
}

.coupon-card-info-footer:hover {
	background: #3370a3 !important;
}

.coupon-card-info-footer a:hover,
.coupon-card-info-footer a:active {
	color: #ffffff;
	text-decoration: none;
}

.coupon-card-buy-footer {
	background: var(--mode-color) !important;
}

.coupon-card-buy-bottom {
	color: #ffffff;
	font-size: 1rem;
}

.coupon-card-buy-footer:hover {
	background: #60b882 !important;
	cursor: pointer;
}

.coupon-card-shop {
	color: #000000;
}

.coupon-card-url {
	color: #000000;
	font-size: 0.8rem;
}

.coupon-card-url:hover,
.coupon-card-url:active {
	color: #000000;
}

.coupon-card-url-details {
	color: #000000;
}

.important-head {
	font-weight: bold;
	font-size: 1.5rem;
	color: red;
}

.important-txt {
	margin-bottom: 2rem;
	margin-top: 1rem;
	font-size: 1.1rem;
}

.coupon-card {
	min-height: 20rem;
	box-shadow: 0px 2px 5px #c8c9cb;
	border: 2px solid black !important;
	margin: 10px;
}

.coupon-card-user {
	min-height: 17rem;
	max-width: 32rem;
	box-shadow: 0px 2px 5px #c8c9cb;
}

.buy-btn {
	float: right;
}

.btn-cad-bottom {
}

.btn-row {
	margin-top: 1.5rem;
}

.buy-btn > svg {
	margin: auto !important;
}

.coupon-card-name {
	margin-top: 1rem;
	color: #000000;
	font-size: 1.5rem;
}

.coupon-card-price {
	color: #000000;
	font-size: 1.5rem;
}

.coupon-card-condition {
	color: #000000;
	font-size: 1rem;
	margin-top: 1rem;
}

.coupon-card-details {
	padding-top: 1rem;
	color: #000000;
	font-size: 0.8rem;
	position: relative;
}

.coupon-card-code {
	color: #000000;
	font-size: 1rem;
}

.coupon-card-text {
	color: #000000;
	font-size: 1rem;
}

.coupon-card-expire {
	color: #000000;
	font-size: 1rem;
}

.coupon-web-link-container {
	position: relative;
}

.actual-points-title {
	font-size: 2.5rem;
	font-weight: bold;
}

.actual-points {
	padding: 20px 0;
}

.qr-image-container {
	margin: 0 1rem;
}

.qr-image {
	max-width: 60vw;
}

.actual-points-text {
	color: #ffffff;
	font-size: 3rem;
	font-weight: bold;
	padding: 0 15px;
	border-radius: 15px;
	background-color: #3370a3;
	box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.5);
}

.history-header {
	padding-top: 2rem;
	padding-bottom: 1rem;
}

.history-header > h1 {
	font-size: 1.6rem;
}

.history-table > tbody > tr > td {
	border-left: 1px solid #c8c9cb;
	border-right: 1px solid #c8c9cb;
}

.history-table > tbody > tr:nth-child(even) {
	background-color: lightblue;
}

.point-input {
	max-width: 200px;
}

.qr-image {
}

.coupon-value {
	margin-bottom: 3rem;
}

.coupon-value-number {
	color: darkblue;
	font-size: 2.7rem;
	font-weight: bold;
}

.coupon-bg {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #e6f5eb;
}

.coupon-card .card-body,
.coupon-card .card-footer {
	z-index: 1;
}

.disabled-coupon .coupon-bg {
	background-color: #999999;
}

.disabled-coupon .coupon-card-shop,
.disabled-coupon .coupon-card-url,
.disabled-coupon .card-text {
	color: #555555;
}

@media screen and (max-width: 570px) {
	.coupon-card {
		max-width: 100% !important;
	}
}
</style>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Vuetable from 'vuetable-2';
import VModal from 'vue-js-modal';
import { Vue } from 'vue-property-decorator';
import NumberInput from '../components/NumberInput.vue';
import TextInput from '../components/TextInput.vue';

Vue.use(VModal, {
	componentName: 'modal'
});

export default {
	name: 'ChargeHistoryView',
	components: { DatePicker, Vuetable, NumberInput, TextInput },
	data() {
		return {
			timer: null,
			showHistory: false,
			couponOffer: true,
			myCoupons: false,
			page: 1,
			totalPage: 1,
			actualPoints: 0,
			history: [],
			categories: [],
			couponInventory: [],
			filteredCouponInventory: [],
			userCoupons: [],
			activeCoupon: null,
			selectedCategory: null,
			refreshTimer: null,
			exchangePointValue: '',
			qrData: null,
			onlineWalletDepositResult: null,
			onlineWalletDepositAmount: 0,
			onlineWalletEmail: '',
			onlineWalletUserCreationMode: false,
			onlineWalletDataPolicyAccepted: false,
			onlineWalletTermsAccepted: false,
			fields: [
				{
					name: 'value',
					title: this.$t('coupon-view.coupon-value'),
					titleClass: 'text-center',
					dataClass: 'text-center'
				},
				{
					name: 'usedAt',
					title: this.$t('coupon-view.coupon-date'),
					titleClass: 'text-center'
				},
				{
					name: 'place',
					title: this.$t('coupon-view.coupon-place'),
					titleClass: 'text-center'
				}
			]
		};
	},
	props: {
		msgs: Object
	},
	computed: {
		maxDepositAmount() {
			return Math.min(this.actualPoints, 100000);
		},

		filteredUserCoupons() {
			if (!this.userCoupons) {
				return [];
			}
			return this.userCoupons.filter((coupon) => coupon.expireDiff >= 0 || !coupon.expire);
		}
	},
	methods: {
		deleteCoupon(coupon) {
			if (confirm(this.$t('cupon-view.delete-coupon'))) {
				this.$rest.deleteUserCoupon(coupon.id, (result) => {
					this.loadUserCoupons();
				});
			}
		},
		removeTraitFilter() {
			this.selectedCategory = null;
			this.filteredCouponInventory = this.couponInventory;
		},
		filterByTrait(key) {
			this.selectedCategory = key;
			this.filteredCouponInventory = this.couponInventory.filter((c) => {
				return c.categories.some((e) => e.key === key);
			});
			//console.log(this.filteredCouponInventory)
		},
		filterData(event) {
			const str = event.target.value.toLowerCase().trim();
			const value = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
			if (value.length > 0) {
				this.filteredCouponInventory = this.couponInventory.filter((inv) => {
					let result = false;
					for (const city of inv.cities) {
						const normalizedName = city
							.toLowerCase()
							.normalize('NFD')
							.replace(/[\u0300-\u036f]/g, '');
						result = normalizedName.includes(value);
						if (result) {
							break;
						}
					}
					return result;
				});
			} else {
				this.filteredCouponInventory = this.couponInventory;
			}
		},
		buyCouponPressed(coupon) {
			if (coupon.styleClass === 'onlinepenztarca') {
				this.$modal.show('onlinewalletmodal');
			} else if (confirm(this.$t('cupon-view.active-confirm'))) {
				this.$rest.buyCoupon(coupon.id, (result) => {
					this.loadUserCoupons();
					this.loadCouponInventory();
					this.loadPoints();
					this.showMyCoupons();
				});
			}
		},
		buyCoupon() {
			this.showHistory = false;
			this.myCoupons = false;
			this.couponOffer = true;
		},
		showMyCoupons() {
			this.showHistory = false;
			this.myCoupons = true;
			this.couponOffer = false;
		},
		showHistoryPanel() {
			this.loadHistory();
			this.showHistory = true;
			this.myCoupons = false;
			this.couponOffer = false;
		},
		discardQRCode() {
			this.$rest.removeActiveCoupon((result) => {
				this.activeCoupon = null;
				this.loadPoints();
			});
		},
		generateQRCode() {
			this.$rest.generateCouponQr(this.exchangePointValue, (result) => {
				if (!result.result) {
					this.msgs = result.messages;
				} else {
					this.activeCoupon = result.data;
					this.exchangePointValue = '';
					this.$forceUpdate();
				}
			});
		},
		loadAvailableCoupon() {
			this.$rest.getAvailableCouponQr((result) => {
				this.activeCoupon = result;
				this.$forceUpdate();
			});
			this.timer = setTimeout(() => {
				this.loadAvailableCoupon();
				this.loadPoints();
			}, 10000);
		},
		isThisPage(i) {
			return i === this.page;
		},
		onChangePage(i) {
			this.page = i;
			//this.loadChargeHistory()
		},
		loadLoggedInUserEmail() {
			this.onlineWalletEmail = localStorage.getItem('loggedInEmail') || '';
		},
		loadPoints() {
			this.$rest.getPoints((result) => {
				this.actualPoints = result;
				this.$forceUpdate();
			});
		},
		loadHistory() {
			this.$rest.getPointsHistory((result) => {
				this.history = result;
				this.$forceUpdate();
			});
		},
		loadCouponInventory() {
			this.$rest.getCouponInventory((result) => {
				this.couponInventory = result;
				this.filteredCouponInventory = result;
				//console.log(result)
				this.$forceUpdate();
			});
		},
		loadCategories() {
			this.$rest.loadCategoryTraits((result) => {
				this.categories = result;
			});
		},
		loadData() {
			this.loadCategories();
			this.loadPoints();
			this.loadLoggedInUserEmail();
			this.loadAvailableCoupon();
			this.loadCouponInventory();
			this.loadUserCoupons();
		},
		refreshCouponExpiry() {
			if (this.refreshTimer) {
				clearTimeout(this.refreshTimer);
			}

			this.userCoupons.forEach((c) => {
				if (!c.expireDt) return;
				const now = new Date();
				const expiry = Date.parse(c.expireDt);
				const diff = (expiry - now) / 1000;
				const minutes = Math.floor(diff / 60);
				const seconds = Math.floor(diff - minutes * 60);
				c.expireDiff = diff;
				c.expireSec = `${minutes} ${this.$t('chargerView.minutes')} ${seconds} ${this.$t('coupon-view.seconds')}`;
			});
			this.$forceUpdate();
			this.refreshTimer = setTimeout(this.refreshCouponExpiry, 1000);
		},
		loadUserCoupons() {
			this.$rest.getUserCoupons((result) => {
				this.userCoupons = result;
				this.refreshCouponExpiry();
			});
		},
		onlineWalletDeposit() {
			this.$rest.isOnlineWalletUserExists(this.onlineWalletEmail, (userExists) => {
				if (userExists === true || (this.onlineWalletUserCreationMode && this.onlineWalletDataPolicyAccepted && this.onlineWalletTermsAccepted)) {
					this.$rest.onlineWalletDeposit(this.onlineWalletDepositAmount, this.onlineWalletEmail, (depositSuccessful) => {
						if (depositSuccessful === true) {
							this.onlineWalletDepositResult = true;
						} else {
							this.onlineWalletDepositResult = false;
						}
					});
				} else {
					this.onlineWalletUserCreationMode = true;
				}
			});
		},
		resetOnlineWalletModal() {
			this.onlineWalletDepositAmount = 500;
			this.loadLoggedInUserEmail();
			this.onlineWalletUserCreationMode = false;
			this.onlineWalletDataPolicyAccepted = false;
			this.onlineWalletTermsAccepted = false;
			this.onlineWalletDepositResult = null;
		},
		onModalClose() {
			this.$modal.hide('onlinewalletmodal');
			this.resetOnlineWalletModal();
		},
		setDepositAmount(amount) {
			this.onlineWalletDepositAmount = Math.max(Math.min(amount, Math.min(this.actualPoints, 100000)), 0);
			this.onlineWalletDepositAmount = this.onlineWalletDepositAmount - (this.onlineWalletDepositAmount % 500);
		}
	},

	beforeDestroy() {
		if (this.timer) {
			clearTimeout(this.timer);
		}
		if (this.refreshTimer) {
			clearTimeout(this.refreshTimer);
		}
	},
	beforeMount() {
		this.loadData();
	}
};
</script>
