<template>

	<div>
		<h2 class="header-common" v-if="!selectedCar && activetab !=='1'"> {{ $t('cars.listHeader') }}</h2>

		<div class="row">
			<div class="col-md-12 vehicle-selector">

				<div id="tabs">
					<div class="tabs text-center" v-if="!selectedCar">
						<a v-on:click="openCarRegistrationForm" v-bind:class="[ activetab === '1' ? 'active' : '' ]" class="btn btn-primary tab-link-register">
							<font-awesome-icon icon="plus"/>
							{{ $t('cars.registerYourCar') }}</a>
						<a v-on:click="activetab='2'" v-bind:class="[ activetab === '2' ? 'active' : '' ]" class="tab-link-my-cars"></a>
					</div>

					<div class="content">
						<div v-if="activetab ==='1'" class="tabcontent hidden-wizard-container">
							<CarRegistrationForm
								@saveCar="saveVehicleEvent"
								@addCar="addToMyCarEvent"
								@changeTab="tabChangeEvent"
								:msgs="msgs"
							/>
						</div>

						<div v-if="selectedCar && selectedCar.addedByUser" class="tabcontent hidden-wizard-container card">
							<div class="media">
								<h3 class="media-body ml-3 mt-3">{{ $t('cars.modifyVehicle') }}</h3>
								<button class="btn btn-danger input-btn-delete" @click="closeDetails()">
									<font-awesome-icon icon="times"/>
								</button>
							</div>
							<CustomCarFormView
								@saveCar="modifyVehicle"
								@closeDetails="closeDetails"
								:modification="true"
								:vehicle="selectedCar"
								:msgs="msgs"
								class="px-3"
							/>

						</div>

						<div v-if="activetab ==='2'" class="tabcontent">
							<div class="row" v-if="cars.length > 1 && !selectedCar">
								<div class="col-2 ">
									<font-awesome-icon size="6x" icon="caret-left" @click="moveFlicking('bw')"/>
								</div>
								<div class="col-8 align-middle progress-container">
									<div class="progress ">
										<div class="thumb" ref="thumb"></div>
									</div>
								</div>
								<div class="col-2 text-right">
									<font-awesome-icon size="6x" icon="caret-right" @click="moveFlicking('fw')"/>
								</div>
							</div>

							<flicking
								class="car-flicking"
								:tag="'div'"
								:options="{ gap: 10,autoResize: true}"
								:viewportTag="'div'"
								:cameraTag="'div'"
								:plugins="plugins"
								ref="carFlicking"
								v-if="cars.length && !selectedCar"
								@move="e => {
									this.$refs.thumb.style.width = (e.progress * 100) + '%';
								}"
							>

								<div class="card car-card" v-for="car in cars" :key="car.id">
									<img v-if="car.image" :src="car.image" class="card-car-img-top"/>
									<span class="card-body">
										<h3 class="card-title text-center car-name">{{ car.producer }} {{ car.model }}</h3>
										<h4 v-if="car.defaultCar" class="default-text text-center">{{ $t('cars.setDefault') }}</h4>
										<span class="row my-3" v-if="car.licensePlate">
											<span class="col-7"><b>{{ $t('cars.licencePlate') }}</b></span>
											<span class="col-5 ">{{ car.licensePlate }}</span>
										</span>
										<span class="row my-3" v-if="car.maxChargingPowerACKw">
											<span class="col-7 "><b>{{ $t('cars.ac-performance') }}</b></span>
											<span class="col-5 ">{{ car.maxChargingPowerACKw }} kW</span>
										</span>
										<span class="row my-3" v-if="car.maxChargingPowerDCKw">
											<span class="col-7 "><b>{{ $t('cars.dc-performance') }}</b></span>
											<span class="col-5 ">{{ car.maxChargingPowerDCKw }} kW</span>
										</span>
										<span class="row my-3" v-if="car.batteryCapacityKWh">
											<span class="col-7 "><b>{{ $t('cars.batteryCapacity') }}</b></span>
											<span class="col-5 ">{{ car.batteryCapacityKWh }} kWh</span>
										</span>
									</span>

									<span class="text-center buttonRow my-2 media">
										<div class="media-body">
											<button class="btn btn-info my-2 col-10 col-sm-10 col-md-10 col-lg-6 " @click="defaultCar(car)" v-if="!car.defaultCar">
												<font-awesome-icon icon="car-side"/>
												{{ $t('cars.setDefault') }}
											</button>

											<button class="btn btn-secondary my-2" @click="details(car)">
												<font-awesome-icon icon="info"/>
												{{ $t('cars.details') }}
											</button>

											<button class="btn btn-danger input-btn-delete my-2" @click="removeCar(car)">
												<font-awesome-icon icon="trash"/>
											</button>
										</div>
									</span>
								</div>
							</flicking>


							<div class="empty-table-msg-container" v-if="!cars.length">
								{{ $t('cars.carsListEmptyMsg') }}
							</div>

							<div v-if="selectedCar && !selectedCar.addedByUser" class="my-4">
								<div class="card car-details">
									<div class="media">
										<h3 class="card-title text-center car-name-detail media-body pt-3 mx-3 mb-0">{{ selectedCar.producer }} {{ selectedCar.model }}</h3>
										<button class="btn btn-danger input-btn-delete" @click="closeDetails()">
											<font-awesome-icon icon="times"/>
										</button>
									</div>
									<div class="card-body">
										<hr style="margin-bottom: 2rem">

										<div class="row my-2">
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.licensePlate"><b>{{ $t('cars.licencePlate') }}</b></div>
											<input  type="text" class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.licensePlate" v-model="selectedCar.licensePlate" @change="licenseChanged"/>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.yearOfProduction"><b>{{ $t('cars.yearOfProduction') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.yearOfProduction">{{ selectedCar.yearOfProduction }}</div>
										</div>

										<div class="row my-2">
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.chargePorts"><b>{{ $t('cars.chargePort') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.chargePorts">{{ formattedChargerPorts }}</div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.enginePowerKw"><b>{{ $t('cars.enginePowerKw') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.enginePowerKw">{{ selectedCar.enginePowerKw }} kW</div>
										</div>
										<div class="row my-2">
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.batteryCapacityKWh"><b>{{ $t('cars.batteryCapacity') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.batteryCapacityKWh">{{ selectedCar.batteryCapacityKWh }} kWh</div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.rangeKm"><b>{{ $t('cars.range') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.rangeKm">{{ selectedCar.rangeKm }} km</div>
										</div>

										<div class="row my-2">
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.topSpeedKmph"><b>{{ $t('cars.max-speed') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.topSpeedKmph">{{ selectedCar.topSpeedKmph }} km/h</div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.accelerationZeroToHundredKmph"><b>{{ $t('cars.acceleration') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.accelerationZeroToHundredKmph">{{ selectedCar.accelerationZeroToHundredKmph }}
												sec
											</div>
										</div>


										<div class="row my-2">
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.rangeEPAKm"><b>{{ $t('cars.epa-range') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.rangeEPAKm">{{ selectedCar.rangeEPAKm }} km</div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.rangeNEDCKm"><b>{{ $t('cars.nedc-range') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.rangeNEDCKm">{{ selectedCar.rangeNEDCKm }} km</div>
										</div>

										<div class="row my-2">
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.rangeWLPTKm"><b>{{ $t('cars.wlpt-range') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.rangeWLPTKm">{{ selectedCar.rangeWLPTKm }} km</div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.averageChargingSpeedDCKmph"><b>{{ $t('cars.avg-dc-speed') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.averageChargingSpeedDCKmph">{{ selectedCar.averageChargingSpeedDCKmph }} km/h
											</div>
										</div>

										<div class="row my-2">
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.maxChargingPowerACKw"><b>{{ $t('cars.max-ac-speed') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.maxChargingPowerACKw">{{ selectedCar.maxChargingPowerACKw }} kW</div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.maxChargingPowerDCKw"><b>{{ $t('cars.max-dc-speed') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.maxChargingPowerDCKw">{{ selectedCar.maxChargingPowerDCKw }} kW</div>
										</div>

										<div class="row my-2">
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.engineTorqueNm"><b>{{ $t('cars.torque') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.engineTorqueNm">{{ selectedCar.engineTorqueNm }} Nm</div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.motorType"><b>{{ $t('cars.motor-type') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.motorType">{{ selectedCar.motorType }}</div>
										</div>

										<div class="row my-2">
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.seats"><b>{{ $t('cars.seat-count') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.seats">
												<span v-for="(seat,index) in selectedCar.seats" :key="seat">
													{{ seat }}<template v-if="index != Object.keys(selectedCar.seats).length - 1">, </template>
												</span>
											</div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.wheelbaseMM"><b>{{ $t('cars.axle-base') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.wheelbaseMM">{{ selectedCar.wheelbaseMM }} mm</div>
										</div>

										<div class="row my-2">
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.bodyStyle"><b>{{ $t('cars.style') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.bodyStyle">{{ selectedCar.bodyStyle }}</div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.cargoVolumeL"><b>{{ $t('cars.trunk-space') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.cargoVolumeL">{{ selectedCar.cargoVolumeL }} L</div>
										</div>

										<div class="row my-2">
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.crubWeightKg"><b>{{ $t('cars.weight') }} </b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.crubWeightKg">{{ selectedCar.crubWeightKg }} kg</div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.towingCapacityKg"><b>{{ $t('cars.tow-weight') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.towingCapacityKg">{{ selectedCar.towingCapacityKg }} kg</div>
										</div>

										<div class="row my-2">
											<div class="col-6 col-md-3 col-lg-3" v-if="selectedCar.efficiencyKWhpKm"><b>{{ $t('cars.efficiency') }}</b></div>
											<div class="col-6 col-md-3 col-lg-3" v-if="selectedCar.efficiencyKWhpKm">{{ selectedCar.efficiencyKWhpKm }} kWh/100km</div>
										</div>

									</div>

								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CarRegistrationForm from "@/components/CarRegistrationForm"
import {Flicking} from "@egjs/vue-flicking"
import {Fade} from "@egjs/flicking-plugins"
import CustomCarFormView from "../components/CustomCarFormView"

export default {
	name: 'CarView',
	components: {CustomCarFormView, CarRegistrationForm, Flicking},
	computed: {
		cancelBtnTitle() {
			return this.$t('cars.cancelButton')
		},
	},
	data() {
		return {
			plugins: [new Fade()],
			selectedCar: null,
			cars: [],
			activetab: '2',
			availablePorts: [],
			formattedChargerPorts: [],
			licensePlateChanged: false,
			carToModify: {
				id: '',
				licensePlate: ''
			}
		}
	},
	props: {
		msgs: Object
	},
	methods: {
		modifyLicensePlateOnVehicle() {
			this.carToModify.id = this.selectedCar.id
			this.carToModify.licensePlate = this.selectedCar.licensePlate
			this.$rest.modifyCar(this.carToModify, usersCars => {
				this.$emit('onSaved', this.carToModify)
				this.cars = usersCars
				this.$emit('loaded', this.cars)
				this.$forceUpdate()
			})
			this.selectedCar = null
		},
		licenseChanged(event) {
			this.licensePlateChanged = true
		},
		openCarRegistrationForm() {
			this.activetab = '1'
			this.selectedCar = null
		},
		moveFlicking(direction) {
			let index = this.$refs.carFlicking.getIndex()
			if(direction === "fw") {
				index++
			}
			else {
				index--
			}
			this.$refs.carFlicking.moveTo(index, 300)
		},
		closeDetails() {
			if(this.licensePlateChanged) {
				if(confirm(this.$t('cars.confirmChangeLicensePlate'))) {
					this.modifyLicensePlateOnVehicle()
				}
				else {
					this.selectedCar = null
				}
			}
			else {
				this.selectedCar = null
			}
		},
		async details(car) {
			this.licensePlateChanged = false
			this.cars.forEach(c => {
				if(c.id === car.id) {
					this.selectedCar = {...c}
				}
			})

			async function formatChargePortValues() {
				let portValues = []
				await this.getChargePorts()
				this.availablePorts.forEach(port => {
					if(this.selectedCar.chargePorts.includes(port.key)) {
						portValues.push(port.value)
					}
				})
				this.formattedChargerPorts = portValues.join(', ')
			}

			if(this.selectedCar && !this.selectedCar.addedByUser) {
				await formatChargePortValues.call(this)
			}
		},
		async getChargePorts() {
			await this.$rest.getChargePorts(ports => {
				this.availablePorts = ports
			})
		},
		saveVehicleEvent(car) {
			this.$emit('onSaved', car)
			this.cars.push(car)
			this.$emit('loaded', this.cars)
			this.$forceUpdate()
			this.activetab = '2'
		},
		modifyVehicle(vehicleToSave) {
			this.$rest.modifyVehicle(vehicleToSave, usersCars => {
				this.$emit('onSaved', vehicleToSave)
				this.cars = usersCars
				this.$emit('loaded', this.cars)
				this.$forceUpdate()
				this.closeDetails()
			})
		},
		addToMyCarEvent(car) {
			this.cars.push(car)
			this.$emit('loaded', this.cars)
			this.$forceUpdate()
			this.activetab = '2'
			this.$emit('onSaved', car)
		},
		tabChangeEvent(tab) {
			this.activetab = tab
			this.$forceUpdate()
		},
		defaultCar(car) {
			this.$rest.defaultCar(car, (myCars, url) => {
				myCars.forEach(c => {
					if(c.image) {
						c.image = url + c.image
					}
				})
				this.cars = myCars
				this.$emit('loaded', this.cars)
				this.$forceUpdate()
			}
			)
		},
		removeCar(car) {
			if(confirm(this.$t('cars.confirmDelete'))) {
				this.$rest.removeMyCars(car, (myCars, url) => {
					this.cars = [...myCars]
					this.$emit('loaded', this.cars)
					myCars.forEach(c => {
						if(c.image) {
							c.image = url + c.image
						}
					})
					this.$forceUpdate()
					this.$store.commit('setUserHasCar', myCars.length > 0)
				})
			}
		},
		loadData() {
			this.$rest.loadMyCars((myCars, url) => {
				myCars.forEach(c => {
					if(c.image) {
						c.image = url + c.image
					}

				})
				this.cars = myCars
				this.$emit('loaded', this.cars)
				this.$forceUpdate()
			}
			)
		}
	},
	beforeMount() {
		this.loadData()
	},
	emits: ['onSaved','loaded']
}

</script>

<style>
.input-btn-delete > svg {
  margin-right: 0px !important;
}

.default-text {
  font-size: 1.2rem;
  color: red;
}

.car-details {
  background-color: #efe !important;
  box-shadow: 0 0 2px lightgrey;
}

.car-name {
  color: #5e987b;
  margin-bottom: 1rem !important;
}

.car-name-detail {
  color: darkblue;
  margin-bottom: 2rem !important;
}

.eg-flick-viewport {
  z-index: 1000 !important;
}

.car-card {
  box-shadow: 0 0px 4px grey;
  overflow: hidden;
  color: black;
  margin: 1.5rem auto;
  width: 25rem !important;
  height: 33rem !important;
}

.card-car-img-top {
  width: 25rem;
  max-height: 14rem;
  object-fit: contain;
}

.car-flicking {
  height: 35rem;
}

@media screen and (max-width: 800px) {
  .car-card {
    width: 20rem !important;
    height: 40rem !important;
  }

  .car-flicking {
    height: 42rem;
  }

  .card-car-img-top {
    width: 20rem;
  }
}

.progress {

}

.progress-container {
  margin: auto;
}

.progress .thumb {
  position: relative;
  height: 100%;
  width: 0;
  border-radius: inherit;
  background: #ccc;
}

.empty-table-msg-container {
  width: 100%;
  text-align: center;
  margin-top: 25px;
  color: #343a40;
}

.hidden-wizard-container .wizard-navigation .wizard-progress-with-circle,
.hidden-wizard-container .vue-form-wizard .wizard-header,
.hidden-wizard-container .vue-form-wizard .wizard-nav-pills,
.hidden-wizard-container .wizard-progress-bar {
  display: none;
}

.hidden-wizard-container .wizard-tab-container .alert.alert-warning {
  margin-top: 15px;
  margin-bottom: 5px;
}

.hidden-wizard-container .wizard-footer-right {
  text-align: right;
}

.hidden-wizard-container .wizard-footer-right .reg-new-car-txt {
  color: #343a40;
  font-weight: 500;
}

.hidden-wizard-container .wizard-footer-right button {
  width: 100%;
  margin-top: 10px;
}

.hidden-wizard-container .wizard-btn {
  background-color: #878789;
  border-color: #6c757d;
  color: #ffffff;
}

.hidden-wizard-container #vehicles > .vs__dropdown-toggle {
  height: calc(1.5em + .75rem + 2px);
}

.hidden-wizard-container .wizard-tab-content {
  -moz-box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.16);
  padding: 20px;
  margin: 20px 0px;
  position: relative;
}

.hidden-wizard-container .vue-form-wizard .wizard-card-footer {
  padding: 0px;
}

.hidden-wizard-container .fake-modal-cancel-button {
  position: absolute;
  right: 0px;
  top: -12px;
  font-size: 40px;
  color: #e74c3c;
  cursor: pointer;
}

.tab-link-register {
  margin: 10px 0 !important;
}

.tab-link-register.active {
  display: none;
}

.tab-link-my-cars {
  display: none;
}
</style>
