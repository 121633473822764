<template>

	<div>

		<div class="payment-card-container my-2">
			<div class="card card-color text-center">
				<div class="card-body card-color">
					<h1 class="card-title my-3 ">
						{{ header }}
					</h1>

					<div class="my-4">
						<div v-if="status==='CANCEL'">
							{{ $t('cards.stopped-payment') }}
						</div>
						<div v-else-if="status==='TIMEOUT'">
							{{ $t('cards.transaction-timeout') }}
						</div>
						<div v-else-if="status==='FAIL'">
							{{ $t('cards.please-check-transaction-details') }}
						</div>
						<div v-else-if="status==='SUCCESS'" class="my-3">
							<b>{{ $t('payment-result-view.charger-starting') }}</b>
						</div>
					</div>

          <div class="text-center mx-auto card-data">
            <div v-if="status==='FAIL' || status==='SUCCESS'" class="text-left ">
              <div class="row my-4">
                <div class="col-5"><b>{{ $t('payment-result-view.transaction-id') }}</b></div>
                <div class="col-7 text-right">{{ transactionId }}</div>
              </div>
              <div class="row my-4">
                <div class="col-5"><b>{{ $t('payment-result-view.receipt-id') }}</b></div>
                <div class="col-7 text-right">{{ orderId }}</div>
              </div>
            </div>
          </div>

					<div class="text-center">
						<router-link
							to="/charge-history"
							tag="button"
							class="btn btn-success px-5 py-3 mt-3"
							v-if="status === 'SUCCESS'"
						>
							<span style="vertical-align: middle;">{{ $t('payment-result-view.go-to-my-charging') }}</span>
							<font-awesome-icon
								icon="chevron-right"
								size="2x"
								:fixed-width="true"
								class="ml-2"
								style="vertical-align: middle;"
							/>
						</router-link>

						<router-link
							to="/"
							tag="button"
							class="btn btn-primary px-5 py-3 mt-3"
							v-else
						>
							<font-awesome-icon
								icon="home"
								size="2x"
								:fixed-width="true"
								class="mr-2"
								style="vertical-align: middle;"
							/>
							<span style="vertical-align: middle;">{{ $t('payment-result-view.back-to-home') }}</span>
						</router-link>
					</div>

				</div>
			</div>

		</div>

		<ChargeDetailsPanel
			:shouldRoute=true
			class="my-3"
		/>
	</div>
</template>

<script>
import ChargeDetailsPanel from '@/components/ChargeDetailsPanel.vue'
import {mapGetters} from "vuex"

export default {
	name: "PaymentResultView",
	data() {
		return {
			header: '',
			orderId: '',
			transactionId: '',
			status: null
		}
	},
	methods: {
		parseSimplePayResponseFromUrl() {
			const responseBase64 = this.$route.query.r
			if(responseBase64) {
				const responseJson = JSON.parse(atob(responseBase64))
				this.status = responseJson.e
				this.orderId = responseJson.o
				this.transactionId = responseJson.t
				if(this.status === 'CANCEL' || this.status === 'TIMEOUT') {
					this.header = this.$t('cards.denied-payment')
				}
				else if(this.status === 'FAIL') {
					this.header = this.$t('cards.denied-transaction')
				}
				else if(this.status === 'SUCCESS') {
					this.header = this.$t('cards.successful-payment')
				}
				else {
					this.result = this.$t('cards.unknown-status')
				}
			}
		}
	},
	components: {
		ChargeDetailsPanel
	},
	beforeMount() {
		this.parseSimplePayResponseFromUrl()
	},
	emits: ['logoutWithRest'],
}

</script>

<style>

.payment-card-container {
  color: white;
  margin: auto;
  max-width: 40rem;
}

.card-color {
  background: #232930;
}

.card-data {
  max-width: 35rem;
}

.dark-background-section {
  background: #232930;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: rgba(255, 255, 255, 0.75);
  font-weight: bold;
}

.dark-background-section > div {
  margin: 1rem
}

.dark-background-section > h1 {
  text-align: center;
}
</style>
